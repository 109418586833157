



import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import MillLogo from "../../Assets/bottomLogo.png";
import LoginScreen from "../../Pages/LoginScreen";
import io from "socket.io-client";
import toast, { Toaster } from "react-hot-toast";
import { setFloor } from "../../redux/slices/floorSlice";




//IMPORTS
//redux imports
import { resetRooms } from "../../redux/slices/floorSlice";


//import Components
import CardContainer from "../../Components/CardGrid/CardGrid";
import {FilterComponent} from "../../Components/FilterComponent/FilterComponent";
import { WeatherContainer } from "../../Components/Weather/Weather.styled";

import Adds from "../../Components/Adds/Adds";
import Carousel from "../../Components/Carousel/Carousel";
import { Weather } from "../../Components/Weather/Weather";
import Floors from "../../Components/Floors/Floors";
import KeyBoardComp from "../../Components/Keyboard/Keyboard";
import { getAllCara } from "../../redux/slices/screenSlice";

//import slide pics gifs
import firstPic from "../../Assets/addsSample/1.gif";
import { setFloorChoice } from "../../redux/slices/screenSlice";
import { getTheme } from "../../redux/slices/themeSlice";
import { screenLogin, updateAll } from "../../redux/slices/screenSlice";


import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import {
  AppContainer,
  AppContainer2,
  MainAppContainer,
  MenuContainer,
  MenuBottomContainer,
  NavBottomCard,
  NavTopCard,
  // HomeContainer,
  // HomeLeft,
  // HomeRight,
  // FilterComponentContainer,
  // ListContainer,
} from "../../App.styled";

import { 
   HomeContainer,
  HomeLeft,
  HomeRight,
  FilterComponentContainer,
  ListContainer,}
  from '../../Pages/Home.styled'




import Home from "../..//Pages/Home";

import mainLogo from "../../Assets/signLogo.webp";











const TemplateOne = ({state, selectedFloorFunc, floorValuesFunc, searchFunc, setFloorFunc }) => {
  //selected floor 
  const [selectedFloor, setSelectedFloor] = useState("all");
  const dispatch = useDispatch();

  //search reducer
  const [search, setSearch] = useState("");
  const [floorValues, setFloorValues] = useState([]);

  // floor reducer
  const floor = useSelector((state) => state.floor);
  const { floorNum } = floor;

  //Screen reducer
  const screenReducer = useSelector((state) => state.screenReducer);
  const { cara, access, floorChoice, themeData } = screenReducer;



  

  const containerStyle = {
    overflow: "hidden",
    maxWidth: "100%",
    maxHeight: "100%",
    border: "solid 1px red",
    background: "white",
    position: "relative",
    paddingTop: "56.25%", // 9 / 16 * 100 to maintain 16:9 aspect ratio
  };

  const contentStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };


  const upSelectedFloorFunc =(val)=>{
    selectedFloorFunc(val)
  }




  //horizontal check
  const [horizontal, setHorizontal] = useState();

  useEffect(() => {
    if (!horizontal) {
      if (window.innerHeight > window.innerWidth) {
        setHorizontal(false);
      } else {
        setHorizontal(true);
      }
    }
  }, [horizontal]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerHeight > window.innerWidth) {
        setHorizontal(false);
      } else {
        setHorizontal(true);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);




  return (
    <HomeContainer horizontal={horizontal}>
  <HomeLeft horizontal={horizontal}>
      {!horizontal ? (
        <>
          <ListContainer>
            <CardContainer
              selectedFloor={selectedFloor}
              floorData={floorValues}
              floorValuesFunc={(val) => floorValuesFunc(val)}
              state={state}
              number={floorNum}
              horizontal={horizontal}
              search={search}
            />
          </ListContainer>

          <FilterComponentContainer
            style={{
              background:
                themeData &&
                themeData.headerBgColor &&
                themeData.headerBgColor,
            }}
          >
            <FilterComponent
              search={search}
              floorValues={floorValues}
              selectedFloor={selectedFloor}
              selectedFloorFunc={(val) => upSelectedFloorFunc(val)}
              searchFunc={(val) => {
                searchFunc(val);
              }}
            />
          </FilterComponentContainer>
        </>
      ) : (
        <>
          <FilterComponentContainer
            horizontal={horizontal}
            style={{
              background:
                themeData &&
                themeData.headerBgColor &&
                themeData.headerBgColor,
            }}
          >
            <FilterComponent
              search={search}
              floorValues={floorValues}
              selectedFloor={selectedFloor}
              selectedFloorFunc={(val) => upSelectedFloorFunc(val)}
              searchFunc={(val) => {
                searchFunc(val);
              }}
              horizontal={horizontal}
            />
          </FilterComponentContainer>
          <ListContainer>
            <CardContainer
              selectedFloor={selectedFloor}
              floorData={floorValues}
              floorValuesFunc={(val) => floorValuesFunc(val)}
              state={state}
              number={floorNum}
              horizontal={horizontal}
              search={search}
            />
          </ListContainer>
        </>
      )}
    </HomeLeft>

    <HomeRight horizontal={horizontal}>
      {horizontal ? (
        <>
          <div
            style={{
              overflow: "hidden",
              maxWidth: "100%",
              maxHeight: "100%",
              boxShadow:
                "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
              background: "white",
              aspectRatio: "16/9",
            }}
          >
            {floorNum ? (
              <Floors selectedFloor={selectedFloor} />
            ) : (
              <Adds horizontal={horizontal} cara={cara && cara} />
            )}
          </div>

          <div
            style={{
              margin: "10px 0px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                paddingRight: "2rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
                gap: "5px",
              }}
            >
              <div
                style={{
                  background:
                    themeData && themeData.headerBgColor
                      ? themeData.headerBgColor
                      : "black",
                  fontWeight: "700",
                  color: "white",
                  padding: "8px",
                  width: "10rem",
                  cursor: "pointer",
                }}
                onClick={() => setFloorFunc(2)}
              >
                Floor Two
              </div>
              <div
                style={{
                  background:
                    themeData && themeData.headerBgColor
                      ? themeData.headerBgColor
                      : "black",
                  fontWeight: "700",
                  color: "white",
                  padding: "8px",
                  width: "10rem",
                  cursor: "pointer",
                }}
                onClick={() => setFloorFunc(1)}
              >
                Floor One
              </div>
              <div
                style={{
                  background:
                    themeData && themeData.headerBgColor
                      ? themeData.headerBgColor
                      : "black",
                  fontWeight: "700",
                  color: "white",
                  padding: "8px",
                  width: "10rem",
                  cursor: "pointer",
                }}
                onClick={() => setFloorFunc("0")}
              >
                Lower Level
              </div>
            </div>
          </div>

          <div
            style={{
              background: "white",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Weather horizontal={horizontal} />
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              background: "white",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Weather style={{ flex: "1" }} horizontal={horizontal} />
            <div
              style={{
                paddingRight: "0rem",
                background: "#e5e5e5",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div
                style={{
                  background:
                    floorChoice.toString() === "2"
                      ? themeData && themeData.cardBgColor
                        ? themeData.cardBgColor
                        : "rgb(12, 42, 149)"
                      : "black",
                  fontWeight: "700",
                  color: "white",
                  padding: "8px",
                  width: "10rem",
                  cursor: "pointer",
                }}
                onClick={() => setFloorFunc(2)}
              >
                Floor Two
              </div>
              <div
                style={{
                  background:
                    floorChoice.toString() === "1"
                      ? themeData && themeData.cardBgColor
                        ? themeData.cardBgColor
                        : "rgb(12, 42, 149)"
                      : "black",
                  fontWeight: "700",
                  color: "white",
                  padding: "8px",
                  width: "10rem",
                  cursor: "pointer",
                }}
                onClick={() => setFloorFunc(1)}
              >
                Floor One
              </div>
              <div
                style={{
                  background:
                    floorChoice.toString() === "0"
                      ? themeData && themeData.cardBgColor
                        ? themeData.cardBgColor
                        : "rgb(12, 42, 149)"
                      : "black",
                  fontWeight: "700",
                  color: "white",
                  padding: "8px",
                  width: "10rem",
                  cursor: "pointer",
                }}
                onClick={() => setFloorFunc("0")}
              >
                Lower Level
              </div>
            </div>
          </div>

          <div
            style={{
              overflow: "hidden",
              maxWidth: "100%",
              maxHeight: "100%",
              boxShadow:
                "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
              background: "white",
              aspectRatio: "16/9",
              margin: ".5rem",
            }}
          >
            {floorNum ? (
              <Floors selectedFloor={selectedFloor} horizontal={horizontal} />
            ) : (
              <Adds horizontal={horizontal} cara={cara} />
            )}
          </div>
        </>
      )}
    </HomeRight>

  </HomeContainer>
  )
}

export default TemplateOne