import styled from 'styled-components'


export const FloorsContainer = styled.div`
width: 100%;

background-color: white;
margin-right:10px;
margin-bottom: ${props=>props.horizontal ? '10px':"0px"};
/* box-shadow: rgba(150, 150, 150, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
/* aspect-ratio:16/9 !important; */
`


export const FloorsControlMain= styled.div`
background: transparent;
width: 44.5px;
max-height:100%;
height: 100%;
right:0px;
display:flex;
justify-content: space-between;
flex-direction: column;
position: absolute;
`

export const FloorsControlBottomContainer= styled.div`

display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
`



export const FloorsControlTopContainer= styled.div`


display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
`



export const FloorsControlButton= styled.div`
margin:10px;
cursor: pointer;
`

export const FloorsControlButtonReset= styled.div`

flex-shrink: 0;
padding: 0px;

background-color: transparent;
position: absolute;
bottom:12rem;
right: 0px;
cursor: pointer;
`

export const PlusMinusButtonContainer = styled.div`
  flex-shrink: 0;
  padding: 0px;
  background-color: white;
  position: absolute;
  bottom: 1rem;
  right: 10px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 3px 0px rgba(102, 102, 102, 0.10),
    0px 0px 6px 0px rgba(102, 102, 102, 0.09),
    0px 0px 8px 0px rgba(102, 102, 102, 0.05),
    0px 0px 9px 0px rgba(102, 102, 102, 0.01),
    0px 0px 10px 0px rgba(102, 102, 102, 0.00);
  width: 47px;
  height: 116px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

`;