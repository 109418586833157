import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFloor } from "../redux/slices/floorSlice";
import io from "socket.io-client";
import {useLocation, useNavigate} from 'react-router-dom'

///TEMPLATE IMPORTS
import TemplateOne from "../template/TemplateOne/TemplateOne";
import TemplateTwo from "../template/TemplateTwo/TemplateTwo";
import Seaty from "../template/Seaty/Seaty";
//IMPORTS


//import slide pics gifs
import firstPic from "../Assets/addsSample/1.gif";
import { setFloorChoice } from "../redux/slices/screenSlice";

import { screenLogin } from "../redux/slices/screenSlice";
import { fetchProjectUpdate } from "../redux/slices/projectSlice";

//MAIN PAGE
const Home = ({ state, resetTheZoom, width, height, deviceType, gallery, svg }) => {


  //selected floor
  const dispatch = useDispatch();

  const location = useLocation()
  const navigate = useNavigate()
  //search reducer
  const [search, setSearch] = useState("");
  const [floorValues, setFloorValues] = useState([]);



  //Screen reducer
  const screenReducer = useSelector((state) => state.screenReducer);
  const {  access,  themeData,projects} = screenReducer;


  //Project Check 
  const projectReducer = useSelector((state)=>state.projectReducer)
  const {updateVersion} = projectReducer



  

  const socket = useMemo(() => io("https://seaty.app/"), []);

  // const socket = useMemo(() => io("http://localhost:5000/"), []);


const checkScreenLogin =()=>{
  dispatch(fetchProjectUpdate({projectId:projects && projects[0] && projects[0]._id}))

}





const updateFunc = async () => {

  dispatch(screenLogin({ logUrl: access && access[0] && access[0]._id }));
};



useEffect(()=>{
  checkScreenLogin()
},[])



useEffect(()=>{
  const update = JSON.parse(localStorage.getItem('updateNum'))

  if(update !== updateVersion){
    updateFunc()
    localStorage.setItem('updateNum',updateVersion)
  }

},[updateVersion])



  useEffect(() => {
  
    socket.emit("joinProject", access?.[0]?.projectsBuilding?.[0]);
  
  }, []);


  useEffect(()=>{
    socket.on("greet", () => updateFunc());
  
    // Clean up when the component unmounts
    return () => {
      socket.off("greet");
      socket.disconnect();
    };
  },[socket])




  const initializeOnLoad = () =>{

    dispatch(screenLogin({logUrl:location.pathname.split('/')[1]}))
    navigate('/')
  }




useEffect(()=>{
 if(location.pathname !== '/'){
initializeOnLoad()
 }
},[location])

  // useEffect(()=>{
  //   dispatch(getTheme(projects && projects.length > 0 && projects[0]._id))
  // },[])


  //horizontal check
  const [horizontal, setHorizontal] = useState();

  useEffect(() => {
    if (!horizontal) {
      if (window.innerHeight > window.innerWidth) {
        setHorizontal(false);
      } else {
        setHorizontal(true);
      }
    }
  }, [horizontal]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerHeight > window.innerWidth) {
        setHorizontal(false);
      } else {
        setHorizontal(true);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  //Carousel Data
  const slideData = [{ slidePic: firstPic }];

  const setFloorFunc = (val) => {
    dispatch(setFloor(val));
    dispatch(setFloorChoice(val));
  };

  //Search Function
  const searchFunc = (val) => {
    setSearch(val);
  };

  //get floor values
  const floorValuesFunc = (val) => {
    setFloorValues(val);
  };

  //selectedFloor Func
  const selectedFloorFunc = (val) => {
    dispatch(setFloorChoice(val));
  };

  const resetTheZoomFunc = (val) => {
    resetTheZoom(val);
  };

  //TEMPLATE CONTENT
  const templateContentMap = {
    2: (
      <TemplateOne
        selectedFloorFunc={(val) => selectedFloorFunc(val)}
        floorValuesFunc={(val) => floorValuesFunc(val)}
        searchFunc={(val) => searchFunc(val)}
        setFloorFunc={(val) => setFloorFunc(val)}
        resetTheZoom={(val) => resetTheZoomFunc(val)}
        state={state}
      />
    ),
    1: (
      <TemplateTwo
        selectedFloorFunc={(val) => selectedFloorFunc(val)}
        floorValuesFunc={(val) => floorValuesFunc(val)}
        searchFunc={(val) => searchFunc(val)}
        setFloorFunc={(val) => setFloorFunc(val)}
        state={state}
        resetTheZoom={(val) => resetTheZoomFunc(val)}
      />
    ),
    3: (
      <Seaty
        selectedFloorFunc={(val) => selectedFloorFunc(val)}
        floorValuesFunc={(val) => floorValuesFunc(val)}
        searchFunc={(val) => searchFunc(val)}
        setFloorFunc={(val) => setFloorFunc(val)}
        state={state}
        resetTheZoom={(val) => resetTheZoomFunc(val)}
        width={width} 
        height={height} 
        deviceType={deviceType} 
        gallery={gallery} 
        svg={svg}

      />
    ),
  };

  const templateContent = useMemo(
    () =>
      templateContentMap[themeData && themeData.template] || (
        <TemplateOne
          selectedFloorFunc={(val) => selectedFloorFunc(val)}
          floorValuesFunc={(val) => floorValuesFunc(val)}
          searchFunc={(val) => searchFunc(val)}
          setFloorFunc={(val) => setFloorFunc(val)}
          state={state}
        />
      ),
    [themeData.template]
  );

  return <> {templateContent}</>;
};

export default Home;
