// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

/*=============================================
=            ASYNC THUNK FUNCTIONS            =
=============================================*/

const update = JSON.parse(localStorage.getItem('updateNum'))

const initialState = {
  access:[],
  cara:[],
  updateVersion: update ? update: 0
};

// ** FETCH DEAL MEMO LIST
export const projectLogin = createAsyncThunk(
  "login-project",
  async ({ logUrl },{dispatch}) => {
    const res = await axios.get(`/api/projects/login/${logUrl}`);
    localStorage.setItem("project", JSON.stringify(res.data));
    dispatch(getAllCara({projectId:res.data[0]._id}))
    return res.data;
  }
);


// ** FETCH ALL CAROUSELS
export const getAllCara = createAsyncThunk(
  "getCara",
  async ({ projectId}) => {
    const res = await axios.get(`/api/projects/cara/`,{projectId});
    localStorage.setItem("cara", JSON.stringify(res.data));
    return res.data;
  }
);


// ** FETCH PROJECT UPDATE ID
export const fetchProjectUpdate = createAsyncThunk(
  "fetch-project-update",
  async ({ projectId },{dispatch}) => {
    const res = await axios.get(`/api/projects/proupdate/${projectId}`);

    return res.data;
  }
);

/*============================================
=             REGISTER EXTRA REDUCERS              =
=============================================*/
function extraReducers(builder) {
  builder.addCase(projectLogin.pending, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  });

  builder.addCase(projectLogin.fulfilled, (state, action) => {
    return {
      ...state,
      loading: false,
      access: [...action.payload],
      units:[...action.payload[0].units]
    };
  });

  builder.addCase(projectLogin.rejected, (state, action) => {
    return {
      ...state,
      loading: false,
      isInitialized: false,
    };
  });
  builder.addCase(getAllCara.pending, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  });

  builder.addCase(getAllCara.fulfilled, (state, action) => {

    return {
      ...state,
      loading: false,
      cara:[...action.payload]
    };
  });

  builder.addCase(getAllCara.rejected, (state, action) => {
    return {
      ...state,
      loading: false,
      isInitialized: false,
    };
  });
  builder.addCase(fetchProjectUpdate.pending, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  });

  builder.addCase(fetchProjectUpdate.fulfilled, (state, action) => {

    return {
      ...state,
      loading: false,
      updateVersion:action.payload
    };
  });

  builder.addCase(fetchProjectUpdate.rejected, (state, action) => {
    return {
      ...state,
      loading: false,
      isInitialized: false,
    };
  });

}

const projectSlice = createSlice({
  name: "project",
  initialState,
  extraReducers,
});

// Action creators are generated for each case reducer function
// export const { } = projectSlice.actions;

export default projectSlice.reducer;
