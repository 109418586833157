// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

const accessData = JSON.parse(localStorage.getItem('screen'))


const initialState = {
  floorNum: accessData && accessData.kioskId && accessData.kioskId === "s-1" ?'1':'0',
  number: "",
};

export const floorSlice = createSlice({
  name: "floor",
  initialState,
  reducers: {
    setFloor: (state, action) => ({
      ...state,
      floorNum: action.payload,
    }),
    selectRoom: (state, action) => ({
      ...state,
      number: action.payload,
    }),
    resetRooms: (state, action) => ({
      floorNum: "",
      number: "",
    }),
  },
});

// Action creators are generated for each case reducer function
export const { setFloor, selectRoom, resetRooms } = floorSlice.actions;

export default floorSlice.reducer;
