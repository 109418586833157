import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Waves from "../Components/Waves/Waves";
import Hand from "../Assets/click SignCast/1.svg";
import Circle from "../Assets/click SignCast/2.svg";
import TopBar from "../Components/topbar/TopBar";
import { screenLogin } from "../redux/slices/screenSlice";
import Autocomplete from "@mui/material/Autocomplete";
import "./styles.css";
import Ripple from '.././Assets/ripple.svg'


import {
  TextField,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
} from "@mui/material";
import InputColor from "../Components/InputColor/InputColor";

const LoginScreen = () => {
  const [logOpen, setLogOpen] = useState(false);
  const [url, setUrl] = React.useState("");
  const location = useLocation();

  const [circles, setCircles] = useState([]);
  const navigate = useNavigate();
  //DISPATCH
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const { userInfo } = auth;

  const themeReducer = useSelector((state) => state.themeReducer);
  const { online } = themeReducer;

  const handleChange = (e) => {
    setUrl(e.target.value);
  };

  const initializeApp = () => {
    localStorage.removeItem("screen");
    localStorage.removeItem("unit");
    localStorage.removeItem("themes");
    localStorage.removeItem("theme");
    localStorage.removeItem("themeData");
    localStorage.removeItem("cara");
    localStorage.removeItem("project");
    dispatch(screenLogin({ logUrl: url }));
  };

  useEffect(() => {
    if (location.pathname !== "/" && userInfo === null) {
      dispatch(screenLogin({ logUrl: location.pathname.split("/")[1] }));
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const numberOfCircles = 50; // or any number you want
    let newCircles = [];

    for (let i = 0; i < numberOfCircles; i++) {
      const size = Math.random() * 120; // Random size up to 50px
      const position = Math.random() * 100; // Random position between 0% and 100%
      const animationDelay = Math.random() * 10 + "s"; // Random delay between 0s and 5s
      const animationDuration = Math.random() * 20 + 10 + "s"; // Random duration between 10s and 30s

      newCircles.push({
        size,
        position,
        animationDelay,
        animationDuration,
      });
    }

    setCircles(newCircles);
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        justifyContent: "center",
        position: "relative",
       background:'white'
      }}
    >
      <div
        style={{
          width: "100px",
          height: "100px",
          position: "absolute",
          zIndex: 999,
          cursor: "pointer",
          padding: "10px",
          opacity:'0'
        }}
        onClick={() => setLogOpen(!logOpen)}
      >
        <svg
          width="10vw"
          height="10vw"
          viewBox="0 0 485 504"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M45.11 278.93C40.27 294.15 38.75 366.95 48.67 383.24C58.34 399.12 112.6 425.42 128.69 444.89C138.68 456.98 152.4 502.98 152.4 502.98L206.34 503.57L249.02 445.49L254.35 501.79L281.62 502.38C281.62 502.38 343.23 428.25 352.16 398.07C358.59 376.33 350.38 307.38 350.38 307.38C350.38 307.38 458.62 183.1 477.82 132.53C486.55 109.52 485.73 50.0602 481.38 34.1402C476.85 17.5702 438.3 4.02017 421.52 0.350166C414.55 -1.16983 393.07 2.73017 393.07 2.73017C393.07 2.73017 430.13 26.7102 436.33 39.4702C441.6 50.3102 437.75 76.8002 432.18 87.4802C427.01 97.3902 410.4 109.01 395.43 112.96C375.79 118.15 298.09 121.64 275.11 139.64C269.48 144.05 262.07 165.12 262.07 165.12C262.07 165.12 276.46 166.24 280.45 168.68C290.57 174.87 317.75 214.76 324.31 233.28C328.08 243.93 325.67 328.17 316.01 353.61C313.54 360.13 256.14 380.72 250.81 376.72C213.35 348.66 130.49 233.28 130.49 233.28C130.49 233.28 141.34 213.82 141.75 203.65C141.85 201.12 132.27 200.09 132.27 200.09C132.27 200.09 52.75 254.99 45.14 278.92L45.11 278.93ZM329.62 170.46C324.38 170.46 320.13 166.21 320.13 160.97C320.13 155.73 324.38 151.48 329.62 151.48C334.86 151.48 339.11 155.73 339.11 160.97C339.11 166.21 334.86 170.46 329.62 170.46Z"
            fill= "#262626"
          />
          <path
            d="M313.39 235.67C308.07 219.37 283.54 186.53 274.27 179.36C259.54 167.97 184.29 152.96 171.14 162.17C162.84 167.98 141 217.26 144.47 232.11C148.13 247.78 235.27 349.57 254.72 361.32C262.04 365.75 302.6 350.78 306.29 345.32C313.9 334.05 317.57 248.46 313.4 235.67H313.39Z"
            fill={online ? "#7AC46F" :'red'}
          />
          <path
            d="M28.52 317.45L10.14 335.83C10.14 335.83 3.62999 330.22 1.24999 322.19C-1.56001 312.71 1.24999 300.85 1.24999 300.85C1.24999 300.85 11.92 309.73 28.52 317.44V317.45Z"
                fill= "#262626"
          />
          <path
            d="M314.21 478.09L314.8 500.61H296.42L314.21 478.09Z"
                fill= "#262626"
          />
          <path
            d="M241.89 502.38H221.74L240.19 477.19L241.89 502.38Z"
                fill= "#262626"
          />
          <path
            d="M20.83 459.91H61.49L66.98 503.57H72.08V445.37H21.82L16.95 382.82H7.96997V503.57H16.95L20.83 459.91Z"
                fill= "#262626"
          />
          <path
            d="M26.01 440.51H69.67C71.02 440.51 72.08 439.23 72.08 437.68C72.08 436.13 71.02 434.82 69.67 434.82H26.01C24.66 434.82 23.57 436.1 23.57 437.68C23.57 439.26 24.66 440.51 26.01 440.51Z"
            fill={online ? "#7AC46F" :'red' }
          />
        </svg>
      </div>
      {!logOpen ? (
        <div
          class="area"
          style={{
            height: "100vh",
            width: "100vw",

            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ul className="circles">
            {circles.map((circle, index) => (
              <li
                key={index}
                style={{
                  width: circle.size + "px",
                  height: circle.size + "px",
                  background:online ? "#7AC46F" :'red',
                  left: circle.position + "%",
                  animationDelay: circle.animationDelay,
                  animationDuration: circle.animationDuration,
                }}
              ></li>
            ))}
          </ul>

          {/* <svg
            width="70vw"
            height="281"
            viewBox="0 0 565 281"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M61.16 92.8202V74.9102C61.16 66.7602 57.18 62.6802 49.21 62.6802H44.94C37.16 62.6802 33.28 66.7602 33.28 74.9102V108.75C33.28 112.54 34.04 115.11 35.56 116.43C37.08 117.76 39.54 119.47 42.95 121.55L68.83 135.49C74.52 138.71 78.54 141.23 80.92 143.02C83.29 144.83 85.14 147 86.46 149.56C87.79 152.13 88.45 157.01 88.45 164.21V207.72C88.45 230.67 77.45 242.14 55.46 242.14H38.68C16.87 242.14 5.97003 229.62 5.97003 204.6V194.36H33.27V205.45C33.27 213.41 37.35 217.4 45.5 217.4H50.34C57.54 217.4 61.15 213.42 61.15 205.45V170.75C61.15 166.96 60.39 164.4 58.87 163.07C57.35 161.75 54.89 160.04 51.48 157.95L25.6 143.45C19.91 140.42 15.79 137.86 13.23 135.77C10.67 133.68 8.82003 130.98 7.69003 127.67C6.55003 124.35 5.98004 120.04 5.98004 114.73V72.9202C5.98004 49.6002 16.88 37.9302 38.69 37.9302H55.75C77.55 37.9302 88.46 49.5902 88.46 72.9202V92.8202H61.16Z"
              fill="#151322"
            />
            <path
              d="M122.59 242.14V37.9302H190.28V62.6802H149.9V124.68H185.74V149.43H149.9V217.41H190.28V242.15H122.59V242.14Z"
              fill="#151322"
            />
            <path
              d="M247.16 242.14H219.86V72.9202C219.86 49.6002 230.76 37.9302 252.57 37.9302H273.05C294.85 37.9302 305.76 49.5902 305.76 72.9202V242.14H278.46V167.34H247.18V242.14H247.16ZM278.44 142.6V74.6202C278.44 66.6602 274.46 62.6702 266.49 62.6702H259.1C251.14 62.6702 247.15 66.6502 247.15 74.6202V142.6H278.43H278.44Z"
              fill="#151322"
            />
            <path
              d="M384.81 62.6699V242.13H357.51V62.6699H331.34V37.9199H410.97V62.6699H384.8H384.81Z"
              fill="#151322"
            />
            <path
              d="M488.9 242.14H461.6V167.06L428.04 37.9399H455.06L475.54 126.68H476.39L494.88 37.9399H521.9L488.91 167.06V242.14H488.9Z"
              fill="#151322"
            />
            <path d="M564.1 0H0.600037V6.55H564.1V0Z" fill="#151322" />
            <path
              d="M564.1 9.83008H0.600037V13.1001H564.1V9.83008Z"
              fill="#6F6672"
            />
            <path
              d="M564.1 273.51H0.600037V280.06H564.1V273.51Z"
              fill="#151322"
            />
            <path
              d="M564.1 266.97H0.600037V270.24H564.1V266.97Z"
              fill="#6F6672"
            />
            <path
              d="M514.448 234.192V242H507.92V234.192H514.448ZM537.623 231.248V234.192C537.623 239.397 535.17 242 530.263 242H526.295C521.388 242 518.935 239.397 518.935 234.192V203.856C518.935 198.651 521.388 196.048 526.295 196.048H530.263C535.17 196.048 537.623 198.651 537.623 203.856V209.04H531.479V204.304C531.479 202.512 530.562 201.616 528.727 201.616H527.511C525.89 201.616 525.079 202.512 525.079 204.304V233.744C525.079 235.536 525.89 236.432 527.511 236.432H529.047C530.668 236.432 531.479 235.536 531.479 233.744V231.248H537.623ZM548.267 242H542.123V203.92C542.123 198.672 544.576 196.048 549.483 196.048H554.091C558.997 196.048 561.451 198.672 561.451 203.92V242H555.307V225.168H548.267V242ZM555.307 219.6V204.304C555.307 202.512 554.411 201.616 552.619 201.616H550.955C549.163 201.616 548.267 202.512 548.267 204.304V219.6H555.307Z"
              fill="#100E20"
            />
          </svg> */}


          
          <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', gap:'6vh'}}>
          {/* <div className="pulse" style={{ background:online ? "#7AC46F" :'red',}} ></div> */}
          <img src={Ripple} style={{width:"40vw"}}/>
          <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', gap:'2vh'}}>
          <svg width="45vw" height="auto" viewBox="0 0 292 94" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.38905 51.884C7.48941 54.7167 7.20513 68.2581 9.05124 71.2848C10.8505 74.2379 20.9405 79.1307 23.9338 82.7527C25.7933 85.0002 28.345 93.5584 28.345 93.5584L38.3782 93.6688L46.3178 82.8664L47.3111 93.3377L52.3812 93.4481C52.3812 93.4481 63.8424 79.6592 65.5012 74.0439C66.6985 70.0006 65.1701 57.1748 65.1701 57.1748C65.1701 57.1748 85.3033 34.0584 88.8718 24.6506C90.4972 20.3698 90.3433 9.30992 89.534 6.35013C88.6912 3.2666 81.5208 0.748279 78.4005 0.0660234C77.1029 -0.21825 73.1097 0.507483 73.1097 0.507483C73.1097 0.507483 80.0025 4.9689 81.1563 7.34342C82.1362 9.36008 81.4205 14.2864 80.3837 16.2729C79.4205 18.1157 76.3337 20.2762 73.5478 21.0119C69.8957 21.9785 55.4413 22.6273 51.1671 25.975C50.1203 26.7944 48.7425 30.714 48.7425 30.714C48.7425 30.714 51.418 30.9214 52.1604 31.3762C54.0433 32.5267 59.1 39.9479 60.3174 43.3926C61.0197 45.3725 60.5716 61.0409 58.7756 65.7733C58.3141 66.9873 47.6388 70.8166 46.6489 70.0741C39.6825 64.8535 24.2682 43.3926 24.2682 43.3926C24.2682 43.3926 26.2882 39.774 26.3618 37.881C26.3819 37.4095 24.596 37.2189 24.596 37.2189C24.596 37.2189 9.80372 47.4326 8.38905 51.8807V51.884ZM61.3073 31.7073C60.3341 31.7073 59.5415 30.918 59.5415 29.9415C59.5415 28.9649 60.3308 28.1756 61.3073 28.1756C62.2839 28.1756 63.0732 28.9649 63.0732 29.9415C63.0732 30.918 62.2839 31.7073 61.3073 31.7073Z" fill="#272626"/>
<path d="M58.2903 43.8374C57.3003 40.8074 52.7386 34.6972 51.0129 33.3628C48.2738 31.2458 34.2775 28.4532 31.8295 30.1655C30.2843 31.2458 26.2243 40.4128 26.8697 43.1752C27.5486 46.0882 43.7589 65.0241 47.3742 67.208C48.7353 68.0307 56.2803 65.2482 56.9659 64.2315C58.3839 62.1346 59.0662 46.2153 58.2903 43.8374Z" fill="#76C375"/>
<path d="M5.30197 59.051L1.88401 62.4689C1.88401 62.4689 0.673337 61.4255 0.231878 59.9339C-0.289847 58.1714 0.231878 55.9641 0.231878 55.9641C0.231878 55.9641 2.21845 57.6162 5.30532 59.051H5.30197Z" fill="#272626"/>
<path d="M58.4414 88.9297L58.5518 93.1169H55.1338L58.4414 88.9297Z" fill="#272626"/>
<path d="M44.9903 93.4479H41.2412L44.6759 88.7625L44.9903 93.4479Z" fill="#272626"/>
<path d="M3.87073 85.5452H11.4357L12.4558 93.6687H13.4056V82.8429H4.05467L3.15169 71.2078H1.47949V93.6687H3.15169L3.87073 85.5452Z" fill="#262626"/>
<path d="M4.83765 81.9367H12.9578C13.2087 81.9367 13.406 81.6993 13.406 81.4116C13.406 81.124 13.2087 80.8799 12.9578 80.8799H4.83765C4.58682 80.8799 4.38281 81.1173 4.38281 81.4116C4.38281 81.7059 4.58682 81.9367 4.83765 81.9367Z" fill="#7AC46F"/>
<path d="M122.791 31.0451V25.0553C122.791 22.3296 121.46 20.9651 118.794 20.9651H117.366C114.764 20.9651 113.467 22.3296 113.467 25.0553V36.3727C113.467 37.6402 113.721 38.4997 114.229 38.9412C114.737 39.386 115.56 39.9579 116.701 40.6535L125.356 45.3156C127.259 46.3925 128.603 47.2353 129.399 47.8339C130.192 48.4393 130.811 49.165 131.252 50.0212C131.697 50.8807 131.918 52.5127 131.918 54.9207V69.4721C131.918 77.1475 128.239 80.9835 120.884 80.9835H115.273C107.978 80.9835 104.333 76.7964 104.333 68.4287V65.004H113.463V68.713C113.463 71.3751 114.828 72.7095 117.553 72.7095H119.172C121.58 72.7095 122.787 71.3784 122.787 68.713V57.1079C122.787 55.8404 122.533 54.9842 122.025 54.5394C121.516 54.098 120.694 53.5261 119.553 52.8271L110.898 47.9777C108.995 46.9644 107.617 46.1082 106.761 45.4093C105.905 44.7103 105.286 43.8073 104.908 42.7003C104.527 41.59 104.336 40.1485 104.336 38.3727V24.3898C104.336 16.5906 107.982 12.6877 115.276 12.6877H120.981C128.272 12.6877 131.921 16.5873 131.921 24.3898V31.0451H122.791Z" fill="#151322"/>
<path d="M143.335 80.9835V12.6877H165.973V20.9651H152.468V41.7003H164.455V49.9777H152.468V72.7129H165.973V80.9869H143.335V80.9835Z" fill="#151322"/>
<path d="M184.996 80.9835H175.866V24.3898C175.866 16.5906 179.512 12.6877 186.806 12.6877H193.655C200.946 12.6877 204.595 16.5873 204.595 24.3898V80.9835H195.464V55.9675H185.003V80.9835H184.996ZM195.458 47.6935V24.9583C195.458 22.2962 194.127 20.9618 191.461 20.9618H188.99C186.327 20.9618 184.993 22.2928 184.993 24.9583V47.6935H195.454H195.458Z" fill="#151322"/>
<path d="M231.032 20.9617V80.9801H221.902V20.9617H213.149V12.6843H239.781V20.9617H231.029H231.032Z" fill="#151322"/>
<path d="M265.843 80.9836H256.713V55.8739L245.489 12.6912H254.526L261.375 42.3693H261.659L267.843 12.6912H276.88L265.847 55.8739V80.9836H265.843Z" fill="#151322"/>
<path d="M290.993 0.00244141H102.537V2.19302H290.993V0.00244141Z" fill="#151322"/>
<path d="M290.993 3.29004H102.537V4.38365H290.993V3.29004Z" fill="#6F6672"/>
<path d="M290.993 91.4749H102.537V93.6654H290.993V91.4749Z" fill="#151322"/>
<path d="M290.993 89.2876H102.537V90.3812H290.993V89.2876Z" fill="#6F6672"/>
<path d="M274.388 78.9278V81.5391H272.205V78.9278H274.388ZM282.138 77.9432V78.9278C282.138 80.6686 281.318 81.5391 279.677 81.5391H278.35C276.709 81.5391 275.888 80.6686 275.888 78.9278V68.7822C275.888 67.0414 276.709 66.1709 278.35 66.1709H279.677C281.318 66.1709 282.138 67.0414 282.138 68.7822V70.516H280.084V68.932C280.084 68.3327 279.777 68.0331 279.163 68.0331H278.757C278.214 68.0331 277.943 68.3327 277.943 68.932V78.7779C277.943 79.3772 278.214 79.6769 278.757 79.6769H279.27C279.813 79.6769 280.084 79.3772 280.084 78.7779V77.9432H282.138ZM285.698 81.5391H283.643V68.8036C283.643 67.0485 284.464 66.1709 286.105 66.1709H287.646C289.287 66.1709 290.107 67.0485 290.107 68.8036V81.5391H288.053V75.9098H285.698V81.5391ZM288.053 74.0476V68.932C288.053 68.3327 287.753 68.0331 287.154 68.0331H286.597C285.998 68.0331 285.698 68.3327 285.698 68.932V74.0476H288.053Z" fill="#100E20"/>
</svg>
<div className='font-load'>Loading up 
the awesomeness...</div>
          </div>
          </div>
        


          
         
        </div>
      ) : (
        <>
          {" "}
          <div
            style={{
              display: "absolute",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "25vh",
              }}
            >
              <TextField
                onChange={({ target }) => setUrl(target.value)}
                fullWidth
                style={{
                  width: "40%",
                  border:'solid 1px black',
                  borderRadius: "10px 0px 0px 10px",
                }}
              />
              <Button
                onClick={() => initializeApp()}
                variant="contained"
                sx={{ padding: "15px", fontSize: "1rem", borderRadius:'0px 10px 10px 0px' }}
              >
                Initialize
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LoginScreen;



