import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import MillLogo from "../../Assets/bottomLogo.png";
import LoginScreen from "../../Pages/LoginScreen";
import io from "socket.io-client";
import toast, { Toaster } from "react-hot-toast";
import { setFloor } from "../../redux/slices/floorSlice";
import FloorTemp2 from "../../Components/Floors/FloorTemp2/FloorTemp2";
import KeyboardComp from "../../Components/Keyboard/Keyboard";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/base/ClickAwayListener";
//IMPORTS
//redux imports
import { resetRooms } from "../../redux/slices/floorSlice";

//import Components
import CardContainer from "../../Components/CardGrid/CardGrid";
import { FilterComponent2 } from "../../Components/FilterComponent/FilterComponent";
import { WeatherContainer } from "../../Components/Weather/Weather.styled";

import Adds from "../../Components/Adds/Adds";
import Carousel from "../../Components/Carousel/Carousel";
import { Weather2 } from "../../Components/Weather/Weather";
import Floors from "../../Components/Floors/Floors";
import KeyBoardComp from "../../Components/Keyboard/Keyboard";
import { getAllCara } from "../../redux/slices/screenSlice";

//import slide pics gifs
import firstPic from "../../Assets/addsSample/1.gif";
import { setFloorChoice } from "../../redux/slices/screenSlice";
import { getTheme } from "../../redux/slices/themeSlice";
import {
  screenLogin,
  updateAll,
  selectFilter,
} from "../../redux/slices/screenSlice";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import {
  HomeContainer,
  HomeLeft,
  HomeRight,
  FilterComponentContainer,
  ListContainer,
  FilterModal,
  FilterLeft,
  FilterRight,
  FilterButton,
  FilterCatItem,
  FilterCatSubtitle,
  FilterCatIcon,
  FilterCatTitle,
  FilterCategoriesContainer,
  FilterTextContainer,
  Circle,
} from "./TemplateTwo.styled";

import Home from "../../Pages/Home";

import mainLogo from "../../Assets/signLogo.webp";

const TemplateTwo = ({
  state,
  selectedFloorFunc,
  floorValuesFunc,
  searchFunc,
  setFloorFunc,
  resetTheZoom,
}) => {
  //selected floor
  const [selectedFloor, setSelectedFloor] = useState("all");
  const dispatch = useDispatch();

  const [svgFloorControl, setSvgFloorControl] = useState();

  //search reducer
  const [search, setSearch] = useState("");
  const [floorValues, setFloorValues] = useState([]);

  // floor reducer
  const floor = useSelector((state) => state.floor);
  const { floorNum } = floor;

  //Screen reducer
  const screenReducer = useSelector((state) => state.screenReducer);
  const { cara, access, floorChoice, themeData } = screenReducer;



  const { categories, amenities } = themeData;

  //Filter Modal
  const [filterModal, setFilterModal] = useState(false);

  const [categSwitch, setCategSwitch] = useState(true);

  //Selected Files
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedAmenities, setSelectedAmenities] = useState("");
  const [colors, setColors] = useState([]);

  const [searchOpen, setSearchOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [resetMainZoom, setResetMainZoom] = useState(false);

  const floorData = [
    { title: "0", subTitle: "" },
    { title: "1", subTitle: "" },
    { title: "2", subTitle: "" },
  ];

  const upSelectedFloorFunc = (val) => {
    selectedFloorFunc(val);
  };

  //horizontal check
  const [horizontal, setHorizontal] = useState();

  useEffect(() => {
    if (!horizontal) {
      if (window.innerHeight > window.innerWidth) {
        setHorizontal(false);
      } else {
        setHorizontal(true);
      }
    }
  }, [horizontal]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerHeight > window.innerWidth) {
        setHorizontal(false);
      } else {
        setHorizontal(true);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  //GENERATE RANDOM COLORS
  const getRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r}, ${g}, ${b})`;
  };

  useEffect(() => {
    const generatedColors = [];
    for (let i = 0; i < floorData.length; i++) {
      generatedColors.push(getRandomColor());
    }
    setColors(generatedColors);
  }, []);

  //Capitilize each Letter in the word
  function capitalizeFirstLetters(sentence) {
    const words = sentence.split(" ");
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase());
    return capitalizedWords.join(" ");
  }

  //MODAL FILTER  OPEN

  const openFilterModal = () => {
    console.log(openFilterModal);
    // setFilterModal(!filterModal);
  };

  const selectedCategoriesFunc = (val) => {
    setSelectedCategory(val);
    dispatch(selectFilter(val));
  };

  const selectedAmenitiesFunc = (val) => {
    setSelectedAmenities(val);
    dispatch(selectFilter(val));
  };

  const keyboardSearchFunc = (val) => {
    setSearch(val);
    searchFunc(val);
  };

  const handleClick = () => {
    setSearchOpen((prev) => !prev);
    setSearch("");
    searchFunc("");
  };

  const handleClickAway = () => {
    setFilterModal(false);
  };

  const searchOpenFunctions = () => {
    setSearchOpen(true);
    setFilterModal((prev) => !prev);
  };

  const filterFunctionOpen = () => {
    if (filterModal && searchOpen) {
      setFilterOpen(true);
      setSearchOpen(false);
    } else if (!filterModal) {
      setFilterOpen(true);
      setSearchOpen(false);
      setFilterModal(!filterModal);
    }
    if (filterModal) {
      setSearchOpen(false);
      setFilterOpen(true);
      setFilterModal(!filterModal);
    } else {
      setFilterModal(!filterModal);
      setFilterOpen(true);
    }
  };

  const resetZoom = () => {
    setResetMainZoom(!resetMainZoom);
  };

  const setZoomValsFunc = (val) => {
    setSvgFloorControl(val);
    resetTheZoom(val);
  };

  const resetTheSvgFloors = () => {
    if (svgFloorControl) {
      const { resetTransform } = svgFloorControl;
      resetTransform();
    }
  };

  return (
    <HomeContainer horizontal={horizontal}>
      <HomeLeft horizontal={horizontal}>
        {!horizontal ? (
          <>
            <ListContainer>
              <CardContainer
                selectedFloor={selectedFloor}
                floorData={floorValues}
                floorValuesFunc={(val) => floorValuesFunc(val)}
                state={state}
                number={floorNum}
                horizontal={horizontal}
                search={search}
              />
            </ListContainer>

            <FilterComponentContainer
              style={{
                background:
                  themeData &&
                  themeData.headerBgColor &&
                  themeData.headerBgColor,
              }}
            >
              <FilterComponent2
                search={search}
                changeSearch={() => setSearch("")}
                floorValues={floorValues}
                selectedFloor={selectedFloor}
                selectedFloorFunc={(val) => upSelectedFloorFunc(val)}
                searchFunc={(val) => {
                  searchFunc(val);
                }}
                filterOpen={() => filterFunctionOpen()}
                searchOpenFunc={() => searchOpenFunctions()}
                resetZoom={() => resetTheSvgFloors()}
              />
            </FilterComponentContainer>
          </>
        ) : (
          <>
            <FilterComponentContainer
              style={{
                background:
                  themeData &&
                  themeData.headerBgColor &&
                  themeData.headerBgColor,
              }}
            >
              <FilterComponent2
                search={search}
                changeSearch={() => setSearch("")}
                floorValues={floorValues}
                selectedFloor={selectedFloor}
                selectedFloorFunc={(val) => upSelectedFloorFunc(val)}
                resetZoom={() => resetTheSvgFloors()}
                searchFunc={(val) => {
                  searchFunc(val);
                }}
                filterOpen={() => filterFunctionOpen()}
                searchOpenFunc={() => searchOpenFunctions()}
              />
            </FilterComponentContainer>
            {filterModal && (
              <ClickAwayListener onClickAway={handleClickAway}>
                <FilterModal
                  style={{
                    height: searchOpen? "400px":'324px' ,
                    top: "190px",
                    width: "48.6vw",
                    left: ".5vw",

                    background: "rgba(255, 255, 255, 0.95)",
                  }}
                >
                  {searchOpen && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          width: "100vw",
                          color: "black",

                          background: "background: rgb(48, 48, 48)",
                          paddingRight: searchOpen && "1rem",
                          paddingLeft: searchOpen && "1rem",
                          paddingTop: searchOpen && "1rem",
                        }}
                      >
                        <KeyboardComp
                          keyboardSearchFunc={(val) => keyboardSearchFunc(val)}
                        />
                      </Box>
                    </div>
                  )}
                  {filterOpen && !searchOpen && (
                    <>
                      <FilterLeft>
                        {categSwitch ? (
                          <FilterCategoriesContainer>
                            {floorData.map((c, i) => (
                              <FilterCatItem
                                isEven={i % 2 === 0}
                                key={i}
                                onClick={() => setFloorFunc(i)}
                                style={{
                                  background:
                                    c.title.toString() ===
                                      floorNum.toString() && "#3E3E3E",

                                  width: "100%",
                                  paddingLeft: "74px",
                                }}
                              >
                                <FilterCatIcon
                                  horizontal={horizontal}
                                  style={{ backgroundColor: colors[i] }}
                                >
                                  {c.title !== "0" &&
                                    capitalizeFirstLetters(`Floor ${c.title}`)}
                                  {c.title === "0" &&
                                    capitalizeFirstLetters(`Lower Level`)}
                                </FilterCatIcon>
                                <FilterTextContainer>
                                  <FilterCatTitle
                                    style={{
                                      color:
                                        c.title.toString() ===
                                          floorNum.toString() && "white",
                                    }}
                                  >
                                    {c.title === "0"
                                      ? "Lower Level"
                                      : "Floor: "}
                                    {c.title !== "0" && c.title}
                                  </FilterCatTitle>
                                  {/* <FilterCatSubtitle>
                                      {c.subTitle}
                                    </FilterCatSubtitle> */}
                                </FilterTextContainer>
                              </FilterCatItem>
                            ))}
                          </FilterCategoriesContainer>
                        ) : (
                          <FilterCategoriesContainer>
                            {amenities &&
                              amenities.map((a, i) => (
                                <FilterCatItem
                                  key={i}
                                  onClick={() => selectedAmenitiesFunc(a.title)}
                                  style={{
                                    background:
                                      a.title === selectedAmenities &&
                                      "rgba(122, 122, 122, 0.05)",
                                  }}
                                >
                                  <Circle></Circle>
                                  <FilterTextContainer>
                                    <FilterCatTitle>{a.title}</FilterCatTitle>
                                    <FilterCatSubtitle></FilterCatSubtitle>
                                  </FilterTextContainer>
                                </FilterCatItem>
                              ))}
                          </FilterCategoriesContainer>
                        )}
                      </FilterLeft>
                      <FilterRight>
                        <FilterButton
                          horizontal={horizontal}
                          style={{
                            color: categSwitch && "white",
                            backgroundColor:
                              categSwitch && "rgba(0, 0, 0, 0.72)",
                          }}
                          // onClick={() => setCategSwitch(false)}
                        >
                          Floors
                        </FilterButton>
                      </FilterRight>
                    </>
                  )}
                </FilterModal>
              </ClickAwayListener>
            )}
            <ListContainer>
              <CardContainer
                selectedFloor={selectedFloor}
                floorData={floorValues}
                floorValuesFunc={(val) => floorValuesFunc(val)}
                state={state}
                number={floorNum}
                horizontal={horizontal}
                search={search}
              />
            </ListContainer>
          </>
        )}
      </HomeLeft>

      <HomeRight horizontal={horizontal}>
        {horizontal ? (
          <>
            <div
              style={{
                overflow: "hidden",
                width: "100%",
                boxShadow:
                  "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
                background: "white",
                aspectRatio: "16/9",
                position: "relative ",
                margin: "10px 0px 0px 0px",
                borderRadius: "5px",
              }}
            >
              <Floors
                resetMainZoom
                resetZoom={resetZoom}
                selectedFloor={selectedFloor}
                vert={false}
                setFloorFunc={(val) => setFloorFunc(val)}
                setZoomResetVal={(val) => setZoomValsFunc(val)}
              />
            </div>

            <div
              style={{
                margin: "10px 0px",
                display: "flex",
                justifyContent: "center",
              }}
            ></div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                gap: "10px",
                margin: "0px 0px 55px 0px",
              }}
            >
              <div
                style={{
                  height: "auto",
                  width: "25vw",
                  aspectRatio: "16/9",
                  overFlow: "hidden",
                  // boxShadow:
                  //   "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
                  background: "white",
                  borderRadius: "5px",
                }}
              >
                <Weather2 style={{ flex: "1" }} horizontal={horizontal} />
              </div>
              <div
                style={{
                  border: "solid 1px black",
                  height: "auto",
                  width: "25vw",
                  aspectRatio: "16/9",
                  overFlow: "hidden",
                  borderRadius: "5px",
                }}
              >
                <Adds horizontal={horizontal} cara={cara && cara} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                background: "white",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "10px",
                gap: ".8rem",
                position: "relative",
              }}
            >
              <Weather2 style={{ flex: "1" }} horizontal={horizontal} />

              {filterModal && (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <FilterModal horizontal={horizontal} style={{ height: searchOpen? "400px":'324px' }}>
                    {searchOpen && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            width: "100%",
                            color: "black",
                            background: "background: rgb(48, 48, 48)",
                            paddingRight: searchOpen && "1rem",
                            paddingLeft: searchOpen && "1rem",
                            paddingTop: searchOpen && "1rem",
                          }}
                        >
                          <KeyboardComp
                            keyboardSearchFunc={(val) =>
                              keyboardSearchFunc(val)
                            }
                          />
                        </Box>
                      </div>
                    )}
                    {filterOpen && !searchOpen && (
                      <>
                        <FilterLeft>
                          {categSwitch ? (
                            <FilterCategoriesContainer>
                              {floorData.map((c, i) => (
                                <FilterCatItem
                                  isEven={i % 2 === 0}
                                  key={i}
                                  onClick={() => setFloorFunc(i)}
                                  style={{
                                    background:
                                      c.title.toString() ===
                                        floorNum.toString() && "#3E3E3E",
                                    width: "100%",
                                    paddingLeft: "74px",
                                  }}
                                >
                                  <FilterCatIcon
                                    style={{ backgroundColor: colors[i] }}
                                  >
                                    {c.title !== "0" &&
                                      capitalizeFirstLetters(
                                        `Floor ${c.title}`
                                      )}
                                    {c.title === "0" &&
                                      capitalizeFirstLetters(`Lower Level`)}
                                  </FilterCatIcon>
                                  <FilterTextContainer>
                                    <FilterCatTitle
                                      style={{
                                        color:
                                          c.title.toString() ===
                                            floorNum.toString() && "white",
                                      }}
                                    >
                                      {c.title === "0"
                                        ? "Lower Level"
                                        : "Floor: "}
                                      {c.title !== "0" && c.title}
                                    </FilterCatTitle>
                                    {/* <FilterCatSubtitle>
                                      {c.subTitle}
                                    </FilterCatSubtitle> */}
                                  </FilterTextContainer>
                                </FilterCatItem>
                              ))}
                            </FilterCategoriesContainer>
                          ) : (
                            <FilterCategoriesContainer>
                              {amenities &&
                                amenities.map((a, i) => (
                                  <FilterCatItem
                                    key={i}
                                    onClick={() =>
                                      selectedAmenitiesFunc(a.title)
                                    }
                                    style={{
                                      background:
                                        a.title === selectedAmenities &&
                                        "rgba(122, 122, 122, 0.05)",
                                    }}
                                  >
                                    <Circle></Circle>
                                    <FilterTextContainer>
                                      <FilterCatTitle>{a.title}</FilterCatTitle>
                                      <FilterCatSubtitle></FilterCatSubtitle>
                                    </FilterTextContainer>
                                  </FilterCatItem>
                                ))}
                            </FilterCategoriesContainer>
                          )}
                        </FilterLeft>
                        <FilterRight>
                          <FilterButton
                            style={{
                              color: categSwitch && "white",
                              backgroundColor:
                                categSwitch && "rgba(0, 0, 0, 0.72)",
                            }}
                            // onClick={() => setCategSwitch(false)}
                          >
                            Floors
                          </FilterButton>
                        </FilterRight>
                      </>
                    )}
                  </FilterModal>
                </ClickAwayListener>
              )}
              <div
                style={{
                  border: "solid 1px black",
                  height: "auto",
                  width: "50vw",
                  aspectRatio: "16/9",
                  overFlow: "hidden",
                  borderRadius: "5px",
                }}
              >
                <Adds horizontal={horizontal} cara={cara && cara} />
              </div>
            </div>

            <div
              style={{
                overflow: "hidden",
                maxWidth: "100%",
                maxHeight: "100%",
                boxShadow:
                  "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
                background: "white",
                aspectRatio: "16/9",
                margin: ".5rem",
              }}
            >
              <div
                style={{
                  overflow: "hidden",
                  width: "100%",

                  boxShadow:
                    "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
                  background: "white",
                  aspectRatio: "16/9",
                  position: "relative ",
                }}
              >
                <Floors
                  resetMainZoom
                  resetZoom={resetZoom}
                  selectedFloor={selectedFloor}
                  vert={true}
                  setFloorFunc={(val) => setFloorFunc(val)}
                  setZoomResetVal={(val) => setZoomValsFunc(val)}
                />
              </div>
            </div>
          </>
        )}
      </HomeRight>
    </HomeContainer>
  );
};

export default TemplateTwo;
