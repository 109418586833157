import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//import styled components
import { CardGridBackground, CardGridContainer } from "./CardGrid.styled";

//import components
import { CardOne, CardTwo,CardThree, CardSeaty, SeatyCode } from "../Cards/Cards";

//import from assets
import signLogo from "../../Assets/mainLogo.png";

import {motion} from 'framer-motion'

const CardGrid = ({
  horizontal,
  number,
  state,
  search,
  floorValuesFunc,
  floorData,
  selectedFloor,
  deviceType
}) => {
  //redux


  const screenReducer = useSelector((state) => state.screenReducer);
  const {  floorValues ,searchData, themeData, tenants} = screenReducer;




  // const userId = userInfo._id;

  //ref card  to add scroll
  const cardConRef = useRef();
  const [finalFilteredData, setFinalFilteredData] = useState([]);

  //search function
  const searchData2 = searchData && searchData.filter((f) =>
  f.name.toString().toLowerCase().includes(search.toLowerCase())
);




  useEffect(() => {
    if (floorData.length < 1) {
      floorValuesFunc(floorValues);
    }
  }, [floorData]);

  useEffect(() => {
    if (state !== "Active") {
      cardConRef.current.scrollTo(0, 0);
    }
  });




  return (
<CardGridContainer horizontal={horizontal} ref={cardConRef}>
  <CardGridBackground
  deviceType={deviceType}
  themeData={themeData}
    style={{
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      width: "100%",
      gap:'16px'

    }}
  >
    {(() => {
      switch (themeData && themeData.template) {
        case 1:
          return searchData2.map((val, i) => (
            <CardThree key={i} values={val} horizontal={horizontal} />
          ));
        case 3:
          return searchData2.map((val, i) => (
            <motion.div     
            initial="offscreen"
            whileInView="onscreen"
            // exit='exit'
            custom={i}  // pass the index of the card
            >
            <SeatyCode key={i} values={val}  deviceType={deviceType}/>
            </motion.div>

          ));
        default:
          return searchData2.map((val, i) => (
            <CardOne key={i} values={val} />
          ));
      }
    })()}
  </CardGridBackground>
</CardGridContainer>



  );
};

export default CardGrid;
