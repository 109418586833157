import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { transform } from "@babel/standalone";
import seatyLogo from '../../Assets/seatyLogo.webp';
import QRCode from "react-qr-code";

const DEVICE_TYPE_TO_VAL = {
  'Mobile': 'mobile',
  'Mobile Landscape': 'mobileLand',
  'Tablet': 'tablet',
  'Base Desktop': 'baseDesk',
  'Vertical': 'kioskVert',
  'Horizontal': 'kioskHor',
};

export const FooterCustomCode = ({ deviceType }) => {
  const { themeData, projects, access } = useSelector(state => state.screenReducer);
  const { online } = useSelector(state => state.themeReducer);

  const val = DEVICE_TYPE_TO_VAL[deviceType] || 'mobile';
  const text2 = themeData && themeData[`${val}FooterTitleStyle`];

  const [DynamicContent, setDynamicContent] = useState(null);

  // Cache previously evaluated JSX to avoid redundant evaluations.
  const evaluatedJSXCache = useRef({});

  useEffect(() => {
    if (text2) {
      const cacheKey = `${text2}_${projects}_${themeData}_${seatyLogo}_${QRCode}_${online}_${access}`;

      if (!evaluatedJSXCache.current[cacheKey]) {
        try {
          const transpiledCode = transform(text2, { presets: ["react"] }).code;
          const Func = new Function("React", "projects", "themeData", "seatyLogo", "QRCode", "online", "access", `return ${transpiledCode};`);
          const content = Func(React, projects, themeData, seatyLogo, QRCode, online, access);
          evaluatedJSXCache.current[cacheKey] = content;
        } catch (error) {
          console.error("Error evaluating JSX:", error);
          evaluatedJSXCache.current[cacheKey] = null; // Store null in cache for error cases.
        }
      }

      setDynamicContent(() => evaluatedJSXCache.current[cacheKey]);
    }
  }, [text2, projects, access, online, themeData, seatyLogo, QRCode]);

  // Conditional rendering for error or loading state.
  if (!DynamicContent) {
    return "Loading..."; // You can customize this message.
  }

  return <>{DynamicContent}</>;
};