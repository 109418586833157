import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { transform } from "@babel/standalone";
import seatyOffline from '../../Assets/seatyLogo.webp';
import seatyLogo from '../../Assets/SeatyOffline.webp';

const DEVICE_TYPE_TO_VAL = {
  'Mobile': 'mobile',
  'Mobile Landscape': 'mobileLand',
  'Tablet': 'tablet',
  'Tablet Landscape': 'baseDesk',
  'Vertical': 'kioskVert',
  'Horizontal': 'kioskHor',
};

export const HeaderCustomCode = ({ deviceType }) => {
  const { themeData, projects, access } = useSelector(state => state.screenReducer);
  const { online } = useSelector(state => state.themeReducer);

  const val = DEVICE_TYPE_TO_VAL[deviceType] || 'mobile';
  const text2 = themeData && themeData[`${val}TitleStyle`];

  const [DynamicContent, setDynamicContent] = useState(null);
  const evaluatedJSXCache = useRef({});
  
  useEffect(() => {
    if (text2) {
      const cacheKey = `${text2}_${projects}_${themeData}_${seatyLogo}_${online}_${seatyOffline}_${access}`;

      if (!evaluatedJSXCache.current[cacheKey]) {
        const transpiledCode = transform(text2, { presets: ["react"] }).code;
        const Func = new Function("React", "projects", "themeData", "seatyLogo", "online", "seatyOffline", "access", `return ${transpiledCode};`);
        const content = Func(React, projects, themeData, seatyLogo, online, seatyOffline, access);
        evaluatedJSXCache.current[cacheKey] = content;
      }

      setDynamicContent(() => evaluatedJSXCache.current[cacheKey]);
    }
  }, [text2, projects, themeData, seatyLogo, online, seatyOffline, access]);

  return <>{DynamicContent || <div style={{display:'none', height:"0px", width:'0px'}}></div>}</>;
};
