import React from 'react'
import {CardLoaderContainer} from './LoadingComponent.styled'
import load from '../../Assets/load.svg'

const LoadingComponent = () => {
  return (
    <CardLoaderContainer>
       <img src={load} alt='signcast load' style={{height:"60px", width:"auto"}}/>
    </CardLoaderContainer>
  )
}

export default LoadingComponent