import styled from "styled-components";

export const CardGridContainer = styled.div`
  overflow: hidden;
  flex-wrap: wrap;
  max-height: 100%;
  padding-bottom: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;




export const CardGridBackground= styled.div`


`;
