import React from 'react'

const NotSupported = () => {
  return (
    <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
      <div><svg width="262" height="262" viewBox="0 0 262 262" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_750_1284)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M152.835 215.291H109.163L116.103 194.049H145.897L152.835 215.291ZM12.6773 27.0603H249.325C250.785 27.0596 252.186 27.639 253.22 28.671C254.253 29.703 254.835 31.1033 254.836 32.5638V164.987H7.16406V32.5638C7.16569 31.103 7.74738 29.7026 8.78125 28.6706C9.81511 27.6385 11.2165 27.0593 12.6773 27.0603ZM249.325 19.8916H12.6773C9.31755 19.8967 6.09678 21.2333 3.72059 23.6086C1.34439 25.9838 0.00649571 29.204 0 32.5638L0 181.377C0.00649533 184.737 1.34437 187.957 3.72055 190.332C6.09674 192.707 9.3175 194.044 12.6773 194.049H108.567L101.627 215.291H95.542C91.9889 215.294 88.5821 216.706 86.0687 219.218C83.5553 221.729 82.1406 225.135 82.1349 228.688V238.536C82.1351 239.006 82.228 239.471 82.4082 239.905C82.5884 240.339 82.8524 240.733 83.1852 241.065C83.5179 241.397 83.9127 241.66 84.3472 241.839C84.7816 242.018 85.2471 242.11 85.717 242.109H176.283C176.752 242.11 177.218 242.018 177.653 241.839C178.087 241.66 178.482 241.398 178.815 241.066C179.148 240.734 179.412 240.34 179.592 239.906C179.772 239.471 179.865 239.006 179.865 238.536V228.687C179.859 225.134 178.445 221.728 175.931 219.217C173.418 216.705 170.011 215.293 166.458 215.291H160.373L153.433 194.049H249.325C252.684 194.044 255.905 192.707 258.281 190.332C260.656 187.956 261.994 184.736 262 181.377V32.5638C261.994 29.2042 260.657 25.984 258.281 23.6087C255.905 21.2334 252.684 19.8967 249.325 19.8916Z" fill="#76C375"/>
<rect x="133.879" y="51.8242" width="5.75824" height="24.1846" rx="2.87912" fill="#76C375"/>
<circle cx="136.758" cy="84.6462" r="2.87912" fill="#76C375"/>
<path d="M105.092 117.688C104.474 119.632 104.28 128.932 105.547 131.013C106.783 133.042 113.714 136.401 115.77 138.889C117.046 140.433 118.799 146.31 118.799 146.31L125.689 146.385L131.141 138.965L131.822 146.158L135.306 146.233C135.306 146.233 143.177 136.763 144.317 132.908C145.139 130.13 144.09 121.322 144.09 121.322C144.09 121.322 157.917 105.446 160.37 98.9855C161.485 96.046 161.381 88.4502 160.825 86.4164C160.246 84.2997 155.321 82.5687 153.178 82.0999C152.287 81.9057 149.543 82.4039 149.543 82.4039C149.543 82.4039 154.278 85.4673 155.07 87.0973C155.743 88.4821 155.251 91.8661 154.54 93.2305C153.879 94.4965 151.757 95.9809 149.845 96.4855C147.336 97.1485 137.41 97.5943 134.474 99.8938C133.755 100.457 132.809 103.149 132.809 103.149C132.809 103.149 134.647 103.292 135.157 103.604C136.449 104.394 139.922 109.49 140.76 111.856C141.241 113.217 140.933 123.978 139.699 127.228C139.384 128.061 132.051 130.691 131.37 130.18C126.585 126.596 116 111.856 116 111.856C116 111.856 117.386 109.37 117.438 108.071C117.451 107.748 116.227 107.616 116.227 107.616C116.227 107.616 106.068 114.629 105.096 117.686L105.092 117.688ZM141.438 103.831C140.769 103.831 140.226 103.288 140.226 102.619C140.226 101.949 140.769 101.406 141.438 101.406C142.107 101.406 142.65 101.949 142.65 102.619C142.65 103.288 142.107 103.831 141.438 103.831Z" fill="#272626"/>
<path d="M139.365 112.161C138.685 110.079 135.551 105.884 134.367 104.968C132.485 103.513 122.873 101.595 121.193 102.772C120.132 103.514 117.342 109.81 117.786 111.707C118.253 113.708 129.385 126.712 131.87 128.213C132.805 128.779 137.986 126.866 138.458 126.169C139.43 124.729 139.899 113.795 139.366 112.161H139.365Z" fill="#76C375"/>
<path d="M102.973 122.608L100.625 124.956C100.625 124.956 99.7937 124.24 99.4896 123.214C99.1307 122.003 99.4896 120.488 99.4896 120.488C99.4896 120.488 100.853 121.622 102.973 122.607V122.608Z" fill="#272626"/>
<path d="M139.47 143.13L139.545 146.007H137.197L139.47 143.13Z" fill="#272626"/>
<path d="M130.23 146.233H127.656L130.013 143.015L130.23 146.233Z" fill="#272626"/>
<path d="M101.99 140.807H107.185L107.886 146.385H108.538V138.95H102.117L101.495 130.959H100.348V146.385H101.495L101.99 140.807Z" fill="#262626"/>
<path d="M102.653 138.329H108.23C108.402 138.329 108.538 138.166 108.538 137.968C108.538 137.77 108.402 137.602 108.23 137.602H102.653C102.48 137.602 102.341 137.766 102.341 137.968C102.341 138.169 102.48 138.329 102.653 138.329Z" fill="#7AC46F"/>
</g>
<defs>
<clipPath id="clip0_750_1284">
<rect width="262" height="262" fill="white"/>
</clipPath>
</defs>
</svg>
</div>
      <div style={{width:"100%", textAlign:'center',    color: '#1F1F1F',
    textAlign: 'center',
    fontFamily: 'Raleway',
    fontSize: '2.5vw',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',   }}>The aspect ratio on your device 
is not ideal for this content.</div>
    </div>
  )
}

export default NotSupported