// ** Redux Imports
import toast from 'react-hot-toast'
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios'


/*=============================================
=                INITIAL STATES              =
=============================================*/
const themeDataJson = JSON.parse(localStorage.getItem('themeData'))
const orientationData = JSON.parse(localStorage.getItem('orientation'))

const initialState = {
    palette: {
        primary: {
          light: '#5454e1',
          main: '#1e5e9d',
          dark:'#093561',
        },
        secondary: {
          light: '#d4939e',
          main: "#093561",
          dark: '#040202',
        },
        mode:'light'
      },
      themeData:themeDataJson ? themeDataJson :[],
      orientation:orientationData ? orientationData :{},
      svgChoice:'',
      online:false,
      resetSearch:false,
};




/*=============================================
=            ASYNC THUNK FUNCTIONS            =
=============================================*/
// ** CRREATE PROJECT
export const getTheme = createAsyncThunk(
  "create-project",
  async (projectId) => {
    const res = await axios.get(`/api/themes/get/${projectId}`);
    return res.data;
  }
);





function extraReducers(builder) {

  //CREATE PROJECTS
  builder.addCase(getTheme.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(getTheme.fulfilled, (state, action) => {
    // toast.success("You have saved your theme!");
    localStorage.setItem('themeData',JSON.stringify(action.payload))
    return{
      ...state,
      themeData:action.payload
    }

  });
  builder.addCase(getTheme.rejected, (state, action) => {
    // toast.error("An error has occured trying to save the theme");

  });

}







export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    changeTheme: (state, action) => {
      return{
        ...state,
       palette:[...action.payload]
      }
    },
    changeOrientation: (state, action) => {
      return{
        ...state,
      orientation:action.payload
      }
    },
    changeSvg: (state, action) => {
      return{
        ...state,
        svgChoice:action.payload
      }
    },

    onlineChange: (state, action) => {
      return{
        ...state,
        online:action.payload,
      }
    },
    resetSearchFunc: (state, action) => {
      return{
        ...state,
        resetSearch:action.payload
      }
    },
  },
  extraReducers

});













export const {changeOrientation, changeSvg, onlineChange, resetSearchFunc } = themeSlice.actions;

export default themeSlice.reducer;
