import React from 'react'
import {
    FlipSvg,
    FlipText,
    FlipWrapper
} from './FlipContainer.styled'




export const FlipContainer = () => {
  return (
    <FlipWrapper>
        <FlipSvg
             animate={{
                scale: [1, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1, 1],
                rotate: [0, 0, 90, 90, 0],
                borderRadius: ["0%", "0%", "50%", "50%", "0%"]
              }}
              transition={{
                duration: 4,
                ease: "easeInOut",
                times: [0, 0.2, 0.5, 0.8, 1],
                repeat: Infinity,
                repeatDelay: 1
              }}
        
        >
        <svg width="20vw" height="20vw" viewBox="0 0 164 164" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_600_1567)">
<path d="M121.398 62.5441L121.398 101.302C121.398 102.581 120.352 103.627 119.073 103.627L46.2083 103.627C44.9293 103.627 43.8828 102.581 43.8828 101.302L43.8828 62.5441C43.8828 61.2651 44.9293 60.2187 46.2083 60.2187L119.073 60.2187C120.352 60.2187 121.398 61.2651 121.398 62.5441Z" fill="#1C1C1C"/>
<path d="M50.0902 62.5442L50.0902 101.302L112.103 101.302L112.103 62.5442L50.0902 62.5442Z" fill="black"/>
<path d="M47.7586 77.3124L47.7586 86.6142C47.7586 87.0406 47.4097 87.3894 46.9834 87.3894C46.5571 87.3894 46.2083 87.0406 46.2083 86.6142L46.2083 77.3124C46.2083 76.886 46.5571 76.5372 46.9834 76.5372C47.4097 76.5372 47.7586 76.886 47.7586 77.3124Z" fill="#999999"/>
<path d="M116.748 77.2721L116.748 86.574C116.748 87.0003 116.399 87.3491 115.972 87.3491C115.546 87.3491 115.197 87.0003 115.197 86.574L115.197 77.2721C115.197 76.8457 115.546 76.4969 115.972 76.4969C116.399 76.4969 116.748 76.8457 116.748 77.2721Z" fill="#999999"/>
<path d="M55.6234 67.192L55.6234 96.6479L70.387 96.6479L70.387 67.192L55.6234 67.192Z" fill="#8AD5DD"/>
<path d="M73.7016 67.192L73.7016 96.6479L88.4652 96.6479L88.4652 67.192L73.7016 67.192Z" fill="#8AD5DD"/>
<path d="M91.7953 67.192L91.7953 96.6479L106.559 96.6479L106.559 67.192L91.7953 67.192Z" fill="#8AD5DD"/>
<path d="M87.1464 140.73C86.7554 140.336 86.7486 139.697 87.1311 139.303L93.3653 132.879C93.7478 132.485 94.3749 132.485 94.766 132.879C95.157 133.273 95.1638 133.912 94.7813 134.306L89.2398 140.016L94.9037 145.727C95.2948 146.121 95.3016 146.76 94.919 147.154C94.5365 147.548 93.9094 147.548 93.5184 147.154L87.1464 140.73ZM123.048 127.759L122.437 126.958L123.048 127.759ZM143.705 96.0533L144.662 96.3229L143.705 96.0533ZM141.273 58.0026L142.188 57.6271L141.273 58.0026ZM116.267 30.2045C115.791 29.9231 115.626 29.3049 115.897 28.8236C116.168 28.3424 116.773 28.1803 117.248 28.4617L116.267 30.2045ZM87.8283 139.007C100.341 139.007 112.506 134.773 122.437 126.958L123.658 128.561C113.388 136.643 100.801 141.026 87.8499 141.026L87.8283 139.007ZM122.437 126.958C132.368 119.143 139.507 108.185 142.747 95.7836L144.662 96.3229C141.312 109.147 133.929 120.478 123.658 128.561L122.437 126.958ZM142.747 95.7836C145.987 83.3828 145.146 70.2345 140.358 58.378L142.188 57.6271C147.144 69.8973 148.013 83.4991 144.662 96.3229L142.747 95.7836ZM140.358 58.378C135.569 46.5218 127.101 36.6194 116.267 30.2045L117.248 28.4617C128.466 35.1041 137.232 45.3566 142.188 57.6271L140.358 58.378Z" fill="#3E3E3E"/>
<path d="M77.4623 22.3511C77.8534 22.7453 77.8602 23.3844 77.4777 23.7786L71.2435 30.2025C70.8609 30.5967 70.2338 30.5967 69.8428 30.2025C69.4518 29.8083 69.4449 29.1692 69.8275 28.775L75.369 23.0648L69.705 17.3547C69.314 16.9605 69.3072 16.3214 69.6897 15.9272C70.0723 15.533 70.6994 15.533 71.0904 15.9272L77.4623 22.3511ZM41.5611 35.3221L42.1718 36.1236L41.5611 35.3221ZM20.904 67.028L19.9467 66.7584L20.904 67.028ZM23.3359 105.079L22.4205 105.454L23.3359 105.079ZM48.3421 132.877C48.8173 133.158 48.9829 133.776 48.712 134.258C48.441 134.739 47.8362 134.901 47.3609 134.62L48.3421 132.877ZM76.7805 24.0743C64.2682 24.0743 52.1027 28.3086 42.1718 36.1236L40.9504 34.5206C51.2212 26.4381 63.8076 22.0554 76.7588 22.0554L76.7805 24.0743ZM42.1718 36.1236C32.2409 43.9387 25.1014 54.8967 21.8614 67.2977L19.9467 66.7584C23.2972 53.9346 30.6798 42.603 40.9504 34.5206L42.1718 36.1236ZM21.8614 67.2977C18.6214 79.6985 19.4625 92.8468 24.2512 104.703L22.4205 105.454C17.4647 93.184 16.5962 79.5822 19.9467 66.7584L21.8614 67.2977ZM24.2512 104.703C29.0398 116.559 37.5082 126.462 48.3421 132.877L47.3609 134.62C36.1427 127.977 27.3764 117.725 22.4205 105.454L24.2512 104.703Z" fill="#3E3E3E"/>
</g>
<defs>
<clipPath id="clip0_600_1567">
<rect width="164" height="164" fill="white" transform="translate(0 164) rotate(-90)"/>
</clipPath>
</defs>
</svg>
        </FlipSvg>
       

<FlipText>Please rotate your phone for
the best experience.</FlipText>

    </FlipWrapper>
  )
}


export default FlipContainer