import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TuneIcon from "@mui/icons-material/Tune";
//MUI

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import ClickAwayListener from "@mui/base/ClickAwayListener";

//IMPORTS
import {
  FilterContainer,
  FilterContainer2,
  SearchInput2,
  SearchInputContainer,
  FilterWord,
  SeatySearchInput2,
  SeatySearchInputContainer,
  SeatyFilterContainer2,
  SeatyFilterButton,
  SeatyResetButton,

} from "./FilterComponent.styled";
import KeyboardComp from "../Keyboard/Keyboard";
import { setFloorChoice, resetData } from "../../redux/slices/screenSlice";

import { resetRooms } from "../../redux/slices/floorSlice";
import { TextField } from "@mui/material";
import { changeSvg } from "../../redux/slices/themeSlice";

///MAIN COMPONENT

export const FilterComponent = ({
  searchFunc,
  selectedFloor,
  selectedFloorFunc,
  horizontal,
}) => {
  //STATE
  const [search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const screenReducer = useSelector((state) => state.screenReducer);
  const { floorValues, floorChoice, themeData } = screenReducer;

  //REDUX
  const dispatch = useDispatch();

  const keyboardSearchFunc = (val) => {
    setSearch(val);
    searchFunc(val);
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
    setSearch("");
    searchFunc("");
  };

  const handleClickAway = () => {
    // setOpen((prev) => !prev);
    // setSearch("");
    // searchFunc("");
  };

  //RESET FUNCTION
  const handleReset = () => {
    setSearch("");
    searchFunc("");
    selectedFloorFunc("all");
    dispatch(resetData());
    dispatch(resetRooms());
    setOpen(false);
  };

  return (
    <FilterContainer horizontal={horizontal}>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "space-around",
          alignItems: "center",
          paddingBottom: ".4rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton type="button" aria-label="search" style={{}}>
            <SearchIcon style={{ color: "white" }} />
          </IconButton>
          <Input
            value={search}
            type="text"
            onClick={handleClick}
            placeholder="Search by company"
            style={{
              color: "black",
              background: "white",
              borderRadius: "5px",
              height: "2rem",
            }}
          />
        </Box>

        <div style={{ display: "flex" }}>
          {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} style={{color:'white !important'}} disabled>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={(e) => selectedFloorFunc(e.target.value)}
              value={floorChoice}
              label="Floors"
              style={{ color: "white !important" }}
            >
              <MenuItem value={"all"} style={{color:'white !important'}}>Floors</MenuItem>
              {floorValues.map((f) => (
                <MenuItem value={f}>
                  {f.toString() === '0' ? "Lower Level" : `Floor ${f}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <Button
            style={{
              background:
                themeData && themeData.cardBgColor
                  ? themeData.cardBgColor
                  : "black",
              fontWeight: "700",
              height: "2.2rem",
            }}
            variant="contained"
            horizontal={horizontal}
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {open && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{ position: "relative", width: "70%", color: "black" }}>
              {open ? (
                <KeyboardComp
                  keyboardSearchFunc={(val) => keyboardSearchFunc(val)}
                />
              ) : null}
            </Box>
          </ClickAwayListener>
        )}
      </div>
    </FilterContainer>
  );
};

export const FilterComponent2 = ({
  searchFunc,
  selectedFloor,
  selectedFloorFunc,
  horizontal,
  filterOpen,
  searchOpenFunc,
  search,
  changeSearch,
  resetZoom,
}) => {
  //STATE

  const [open, setOpen] = React.useState(false);
  const screenReducer = useSelector((state) => state.screenReducer);
  const { floorValues, floorChoice, themeData } = screenReducer;

  //REDUX
  const dispatch = useDispatch();

  const keyboardSearchFunc = (val) => {
    // setSearch(val);
    searchFunc(val);
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
    changeSearch("");
    searchFunc("");
    searchOpenFunc();
  };


  const handleSearchClose = () => {

  };

  const handleClickAway = () => {
    // setOpen((prev) => !prev);
    // setSearch("");
    // searchFunc("");
  };

  //RESET FUNCTION
  const handleReset = () => {
    changeSearch("");
    searchFunc("");
    selectedFloorFunc("all");
    dispatch(resetData());
    dispatch(resetRooms());
    setOpen(false);
    resetZoom();
  };

  return (
    <FilterContainer2 horizontal={horizontal}>
      <SearchInputContainer>
        <div onClick={() => handleClick()}>
          <SearchIcon style={{ color: "white", fontSize: "2rem" }} />
        </div>
        <SearchInput2
          onselectstart="return false"
          onClick={() => handleClick()}
          value={search}
          type="text"
          placeholder="Search by company"
        />
        <div
          style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
          onClick={() => filterOpen()}
        >
          <TuneIcon style={{ fontSize: "2rem" }} />{" "}
          <FilterWord>FILTER</FilterWord>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
          onClick={() => handleReset()}
        >
          {" "}
          <FilterWord>RESET</FilterWord>
        </div>
      </SearchInputContainer>
    </FilterContainer2>
  );
};

export const SeatyFilter = ({
  deviceType,
  searchFunc,
  selectedFloor,
  selectedFloorFunc,
  horizontal,
  filterOpen,
  searchOpenFunc,
  search,
  changeSearch,
  resetZoom,
  setSearchFunc
}) => {

  //REDUX
  const dispatch = useDispatch();



  const handleClick = () => {

    changeSearch("");
    searchFunc("");
    searchOpenFunc();
    dispatch(setFloorChoice('all'))
  };



  //RESET FUNCTION
  const handleReset = () => {
    changeSearch("");
    searchFunc("");
    selectedFloorFunc("all");
    dispatch(resetData());
    dispatch(resetRooms());

    resetZoom();
    dispatch(changeSvg(''))
  };

  const handleSearchClose =()=>{
    dispatch(setFloorChoice('all'))
  }


  return (
    <SeatyFilterContainer2 horizontal={horizontal}>
      <SeatySearchInputContainer deviceType ={deviceType}>
        <div style={{margin:'10px'}}>
        <svg              xmlns="http://www.w3.org/2000/svg" width={deviceType ==='Vertical'?"35":"20"} height={deviceType ==='Vertical'?"35":"20"} viewBox="0 0 37 37" fill="none">
  <path d="M16.8972 29.2997C23.5375 29.2997 28.9204 23.9271 28.9204 17.2997C28.9204 10.6723 23.5375 5.29968 16.8972 5.29968C10.257 5.29968 4.87402 10.6723 4.87402 17.2997C4.87402 23.9271 10.257 29.2997 16.8972 29.2997Z" stroke="#141918" stroke-width="1.37749" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M31.9263 32.2997L25.3887 25.7747" stroke="#141918" stroke-width="1.37749" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </div>
        <SeatySearchInput2
          // onselectstart="return false"
          onChange={(e)=>setSearchFunc(e.target.value)}
          onClick={() => {
            if (deviceType !== "Mobile" && deviceType !== "Tablet") {
              handleClick();
            }else{
              handleSearchClose()
            }
          }}
          value={search}
          deviceType={deviceType}
          type="text"
          placeholder={deviceType === "Vertical" ? "Type your name to find your seat...":"Type your name..."}
        />
        <div style={{ display: "flex", height: "100%" }}>
          <SeatyFilterButton deviceType={deviceType} onClick={() => filterOpen()} >

            <FilterWord deviceType={deviceType}>FILTER</FilterWord>
          </SeatyFilterButton>
          <SeatyResetButton deviceType={deviceType} onClick={() => handleReset()}>
            {" "}
            <FilterWord deviceType={deviceType}>RESET</FilterWord>
          </SeatyResetButton>
        </div>
      </SeatySearchInputContainer>
    </SeatyFilterContainer2>
  );
};
