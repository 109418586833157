import React, { useEffect, useState } from "react";
import {
  WeatherContainer,
  WeatherCard,
  WeatherCardMain,
  WeatherContainer2,
  Weather2Top,
  Weather2TopLeft,
  Weather2TopRight,
  Weather2Bottom,
  WeatherCard2,
  WeatherInfo
} from "./Weather.styled";

import axios from "axios";

import clearDay from "../../Assets/weather/sun.svg";
import clearNight from "../../Assets/weather/clearNight.svg";
import cloudDay from "../../Assets/weather/partlycloud.svg";
import cloudNight from "../../Assets/weather/cloudNight.svg";
import scatCloud from "../../Assets/weather/scatClouds.svg";
import rain from "../../Assets/weather/rain.svg";
import thunder from "../../Assets/weather/thunder.svg";
import snow from "../../Assets/weather/snow.svg";
import mist from "../../Assets/weather/mist.svg";
import { useSelector } from "react-redux";

export const Weather = ({ horizontal }) => {
  const [weatherData, setWeatherData] = useState(null);
  const [newWeather, setNewWeather] = useState([]);
  const [weatherError, setWeatherError] = useState(null);
  const [location, setLocation] = useState("brampton");
  const [checkDate, setCheckDate] = useState();

  const screenReducer = useSelector((state) => state.screenReducer);
  const { themeData } = screenReducer;

  const icons = [
    clearDay,
    clearNight,
    cloudDay,
    cloudNight,
    scatCloud,
    rain,
    thunder,
    snow,
    mist,
  ];

  useEffect(() => {
    if (themeData && themeData.weatherLocation) {
      setLocation(themeData && themeData.weatherLocation);
    }
  }, [themeData]);

  const getIcon = (weather) => {
    switch (weather) {
      case "01d":
        return icons[0];
        break;
      case "01n":
        return icons[1];
        break;
      case "02d":
        return icons[2];
        break;
      case "02n":
        return icons[3];
        break;
      case "03d":
        return icons[4];
        break;
      case "03n":
        return icons[4];
        break;
      case "04d":
        return icons[4];
        break;
      case "04n":
        return icons[4];
        break;
      case "09d":
        return icons[5];
        break;
      case "09n":
        return icons[5];
        break;

      case "10d":
        return icons[5];
        break;

      case "10n":
        return icons[5];
        break;

      case "11d":
        return icons[6];
        break;

      case "11n":
        return icons[6];
        break;

      case "13d":
        return icons[7];
        break;
      case "13n":
        return icons[7];
        break;
      case "50d":
        return icons[8];
        break;
      case "50n":
        return icons[8];
        break;
      default:
        return icons[0];
    }
  };

  //fetch weather api
  const fetchWeatherData = async () => {
    try {
      let res = await axios.get(
        `https://api.openweathermap.org/data/2.5/forecast?q=${location}&units=metric&appid=${process.env.REACT_APP_WEATHER}`
      );
      setWeatherData(res.data);
    } catch (err) {
      setWeatherError(err);
    }
  };
  let dateCheck = [];
  let newWeatherArray = [];
  let newWeatherData =
    weatherData &&
    weatherData.list.map((l) => {
      if (!dateCheck.includes(l.dt_txt.split(" ")[0])) {
        newWeatherArray.push(l);
        dateCheck.push(l.dt_txt.split(" ")[0]);
      }
    });

  //getDayFunc

  const getDayFunc = (date) => {
    const result = new Date(date).getDay();

    // Sunday - Saturday : 0 - 6
    switch (result) {
      case 1:
        return `Mon`;
        break;
      case 2:
        return "Tues";
        break;
      case 3:
        return "Wed";
        break;
      case 4:
        return "Thurs";
        break;
      case 5:
        return "Fri";
        break;
      case 6:
        return "Sat";
        break;
      case 0:
        return "Sun";
        break;
      default:
        return " ";
        break;
    }
  };

  //weather data call if none
  useEffect(() => {
    fetchWeatherData();
    // Fetch weather data again if current day changes
    const interval = setInterval(() => {
      const currentTime = new Date();
      const currentDay = currentTime.getDay();

      if (currentDay !== getStoredDay()) {
        fetchWeatherData();
        storeCurrentDay(currentDay);
      }
    }, 60000); // Check every minute

    // Function to store the current day in localStorage
    const storeCurrentDay = (day) => {
      localStorage.setItem("currentDay", day);
    };

    // Function to retrieve the stored day from localStorage
    const getStoredDay = () => {
      const storedDay = localStorage.getItem("currentDay");
      return storedDay ? parseInt(storedDay) : null;
    };

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);

  return (
    <WeatherContainer>
      {weatherData !== null ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        >
          <WeatherCardMain
            horizontal={horizontal}
            style={{
              background:
                themeData &&
                themeData.weatherBgColor &&
                themeData.weatherBgColor,
              color:
                themeData && themeData.weatherColor && themeData.weatherColor,
            }}
          >
            <div style={{ lineHeight: ".1px" }}>
              <p style={{ fontSize: "1.4rem" }}>
                {location.slice(0, 1).toUpperCase() + location.slice(1)}
              </p>
              <p style={{ fontSize: "1.2rem" }}>Weather</p>
            </div>

            <div>
              <p>
                <img
                  src={getIcon(weatherData.list[0].weather[0].icon)}
                  style={{ maxWidth: "75px" }}
                  alt="weathericons"
                />
              </p>
            </div>

            <div>
              <p style={{ fontSize: "1.5rem" }}>
                {`${weatherData.list[0].main.temp}`} &deg;C{" "}
              </p>
            </div>
          </WeatherCardMain>
          {horizontal &&
            newWeatherArray
              .slice(1, newWeatherArray.length - 1)
              .map((list, i) => (
                <WeatherCard
                  key={i}
                  style={{
                    background:
                      themeData &&
                      themeData.weatherBgColor &&
                      themeData.weatherBgColor,
                    color: themeData && themeData.weatherColor,
                  }}
                >
                  <div style={{ lineHeight: "3px" }}>
                    <p>{getDayFunc(list.dt_txt)}</p>
                    <p>{list.dt_txt.slice(5, 10)}</p>
                  </div>

                  <div>
                    <p>
                      <img
                        src={getIcon(list.weather[0].icon)}
                        style={{ maxWidth: "50px" }}
                        alt="weatherIcons"
                      />
                    </p>
                  </div>

                  <div>
                    <p>{`${list.main.temp}`} &deg;C </p>
                  </div>
                </WeatherCard>
              ))}
        </div>
      ) : (
        "loading weather"
      )}
    </WeatherContainer>
  );
};

export const Weather2 = ({ horizontal }) => {
  const [weatherData, setWeatherData] = useState(null);
  const [newWeather, setNewWeather] = useState([]);
  const [weatherError, setWeatherError] = useState(null);
  const [location, setLocation] = useState("brampton");
  const [checkDate, setCheckDate] = useState();

  //TIME
  // GET DATE AND TIME
  const [date, setDate] = useState(new Date().toString());
  const [time, setTime] = useState(new Date().toLocaleTimeString());

  function newDates() {
    setTime(new Date().toLocaleTimeString());
    setDate(new Date().toString());
  }

  useEffect(() => {
    const interval = setInterval(() => newDates(), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const screenReducer = useSelector((state) => state.screenReducer);
  const { themeData } = screenReducer;

  const icons = [
    clearDay,
    clearNight,
    cloudDay,
    cloudNight,
    scatCloud,
    rain,
    thunder,
    snow,
    mist,
  ];

  useEffect(() => {
    if (themeData && themeData.weatherLocation) {
      setLocation(themeData && themeData.weatherLocation);
    }
  }, [themeData]);

  const getIcon = (weather) => {
    switch (weather) {
      case "01d":
        return icons[0];
        break;
      case "01n":
        return icons[1];
        break;
      case "02d":
        return icons[2];
        break;
      case "02n":
        return icons[3];
        break;
      case "03d":
        return icons[4];
        break;
      case "03n":
        return icons[4];
        break;
      case "04d":
        return icons[4];
        break;
      case "04n":
        return icons[4];
        break;
      case "09d":
        return icons[5];
        break;
      case "09n":
        return icons[5];
        break;

      case "10d":
        return icons[5];
        break;

      case "10n":
        return icons[5];
        break;

      case "11d":
        return icons[6];
        break;

      case "11n":
        return icons[6];
        break;

      case "13d":
        return icons[7];
        break;
      case "13n":
        return icons[7];
        break;
      case "50d":
        return icons[8];
        break;
      case "50n":
        return icons[8];
        break;
      default:
        return icons[0];
    }
  };

  //fetch weather api
  const fetchWeatherData = async () => {
    try {
      let res = await axios.get(
        `https://api.openweathermap.org/data/2.5/forecast?q=${location}&units=metric&appid=${process.env.REACT_APP_WEATHER}`
      );
      setWeatherData(res.data);
    } catch (err) {
      setWeatherError(err);
    }
  };
  let dateCheck = [];
  let newWeatherArray = [];
  let newWeatherData =
    weatherData &&
    weatherData.list.map((l) => {
      if (!dateCheck.includes(l.dt_txt.split(" ")[0])) {
        newWeatherArray.push(l);
        dateCheck.push(l.dt_txt.split(" ")[0]);
      }
    });

  //getDayFunc

  const getDayFunc = (date) => {
    const result = new Date(date).getDay();

    // Sunday - Saturday : 0 - 6
    switch (result) {
      case 1:
        return `Mon`;
        break;
      case 2:
        return "Tues";
        break;
      case 3:
        return "Wed";
        break;
      case 4:
        return "Thurs";
        break;
      case 5:
        return "Fri";
        break;
      case 6:
        return "Sat";
        break;
      case 0:
        return "Sun";
        break;
      default:
        return " ";
        break;
    }
  };

  //weather data call if none
  useEffect(() => {
    fetchWeatherData();
    // Fetch weather data again if current day changes
    const interval = setInterval(() => {
      const currentTime = new Date();
      const currentDay = currentTime.getDay();

      if (currentDay !== getStoredDay()) {
        fetchWeatherData();
        storeCurrentDay(currentDay);
      }
    }, 60000); // Check every minute

    // Function to store the current day in localStorage
    const storeCurrentDay = (day) => {
      localStorage.setItem("currentDay", day);
    };

    // Function to retrieve the stored day from localStorage
    const getStoredDay = () => {
      const storedDay = localStorage.getItem("currentDay");
      return storedDay ? parseInt(storedDay) : null;
    };

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);

  return (
    <WeatherContainer2  
    style={{
      background:
        themeData &&
        themeData.weatherBgColor &&
        themeData.weatherBgColor,
      color:
        themeData && themeData.weatherColor && themeData.weatherColor,
    }}>
      <Weather2Top>
        <Weather2TopLeft>
     
          <div
            style={{
              lineHeight: "1.5rem",
              display: "flex",
              flexDirection: "column",
              height: "100%",
             justifyContent:'center',
             alignItems:'center'
            }}
          >
            <div
              style={{
                fontFamily:
                  themeData &&
                  themeData.timeFontFamily &&
                  themeData.timeFontFamily,
                fontSize:
                  themeData && themeData.timeFontSize
                    ? themeData.timeFontSize
                    : "1.8rem",
                color:
                  themeData && themeData.timeColor
                    ? themeData.timeColor
                    : "black",
                fontWeight: "600",
                right: "2vw",
              }}
              id="tt"
            >
              {time.split(" ")[0].slice(0, time.split(" ")[0].length - 3) + " "}
              <span style={{ fontSize: "1.8rem" }}>{time.split(" ")[1]}</span>
            </div>
            <div
              style={{
                fontFamily:
                  themeData &&
                  themeData.dateFontFamily &&
                  themeData.dateFontFamily,
                fontSize:
                  themeData && themeData.dateFontSize
                    ? themeData.dateFontSize
                    : "1rem",
                color:
                  themeData && themeData.dateColor
                    ? themeData.dateColor
                    : "black",
            
                  
            
              }}
            >
              <div >{date.split(":")[0].slice(0, date.split(":")[0].length - 8)}</div>
              
            </div>
          </div>
        </Weather2TopLeft>
        <Weather2TopRight>
        <div>
            <img
                  src={getIcon(weatherData && weatherData.list[0].weather[0].icon)}
                  style={{ maxWidth: "75px" }}
                  alt="weathericons"
                />
            </div>   
          <div style={{ lineHeight: ".1px" }}>
        <p style={{ fontSize: "1.5rem" }}>
                {`${weatherData && weatherData.list[0].main.temp.toFixed(0)}`} &deg;C
              </p>
              <p style={{ fontSize: "1.4rem" }}>
                {location.slice(0, 1).toUpperCase() + location.slice(1)}
              </p>
       
            </div>



</Weather2TopRight>
      </Weather2Top>
      <Weather2Bottom>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            height: "100%",
            paddingTop:'40px'
          }}
        >
          {newWeatherArray &&
            newWeatherArray.map((list, i) => (
              <WeatherCard2
                key={i}
                style={{
                  background:
                    themeData &&
                    themeData.weatherBgColor &&
                    themeData.weatherBgColor,
                  color: themeData && themeData.weatherColor,
                  overflow: "hidden",
                }}
              >


                <div>
                    <img
                      src={getIcon(list.weather[0].icon)}
                      style={{ maxWidth: "50px",fill: '#000000 !important'  }}
                      alt="weatherIcons"
                    />
                </div>

                <div >
                  <WeatherInfo style={{lineHeight: "10px"}}>{`${list.main.temp.toFixed(0)}`} &deg;C </WeatherInfo>
                  <WeatherInfo style={{lineHeight: "10px"}}>{getDayFunc(list.dt_txt)}</WeatherInfo>
                  <WeatherInfo style={{lineHeight: "10px"}}>{list.dt_txt.slice(5, 10)}</WeatherInfo>
   
                </div>
              </WeatherCard2>
            ))}
        </div>
      </Weather2Bottom>
    </WeatherContainer2>
  );
};
