// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";



/*=============================================
=            ASYNC THUNK FUNCTIONS            =
=============================================*/
const getScreens = JSON.parse(localStorage.getItem("screen"));
const getUnits = JSON.parse(localStorage.getItem("unit"));
const getTenants = JSON.parse(localStorage.getItem("tenant"));
// const getCara = JSON.parse(localStorage.getItem("cara"));
const getProject = JSON.parse(localStorage.getItem("project"));

const getTheme = JSON.parse(localStorage.getItem("themes"));

const initialState = {
  access: getScreens ?[getScreens]:  [],
  themeData: getTheme && getTheme,
  units: getUnits && [...getUnits],
  tenants:getTenants && [...getTenants],
  projects:getProject && [getProject],
  searchData: getTenants && [...getTenants],
  selectedCategories: "",
  floorValues:[],
  floorChoice: "all",
  cara: [],
};

// ** FETCH ALL CAROUSELS
export const getAllCara = createAsyncThunk("getCara", async ({ projectId }) => {
  const res = await axios.get(`/api/projects/cara/${projectId}`);
  localStorage.setItem("cara", JSON.stringify(res.data));
  return res.data;
});
// ** FETCH ALL CAROUSELS
export const updateAll = createAsyncThunk(
  "updateAll",
  async ({ projectId }) => {
    const res = await axios.get(`/api/projects/cara/${projectId}`);
    localStorage.setItem("cara", JSON.stringify(res.data));
    return res.data;
  }
);

// ** FETCH DEAL MEMO LIST
export const screenLogin = createAsyncThunk(
  "screen-project",
  async ({ logUrl }, { dispatch }) => {
    const res = await axios.get(`/api/screens/getscreen/${logUrl}`);
    localStorage.setItem("screen", JSON.stringify(res.data.screen));
    localStorage.setItem("unit", JSON.stringify(res.data.units));
    localStorage.setItem("tenant", JSON.stringify(res.data.tenants));
    // localStorage.setItem("cara", JSON.stringify(res.data.project.carousel));
    localStorage.setItem("project", JSON.stringify(res.data.project));
    localStorage.setItem("themes", JSON.stringify(res.data.theme));

    return res.data;
  }
);

/*============================================
=             REGISTER EXTRA REDUCERS              =
=============================================*/
function extraReducers(builder) {
  builder.addCase(screenLogin.pending, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  });

  builder.addCase(screenLogin.fulfilled, (state, action) => {

    return {
      ...state,
      access: [action.payload.screen],
      themeData: action.payload.theme && action.payload.theme,
      units: action.payload.units && [...action.payload.units],
      tenants: action.payload.tenants && [...action.payload.tenants],
      projects:action.payload.project && [action.payload.project],
      searchData: action.payload.tenants && [...action.payload.tenants],
      floorValues: action.payload.tenants
        ? [
            ...new Set(
              action.payload.tenants &&
                action.payload.tenants.map((post) => post.unit)
            ),
          ]
        : [],
    };
  });

  builder.addCase(screenLogin.rejected, (state, action) => {
    return {
      ...state,
      loading: false,
      isInitialized: false,
    };
  });

  builder.addCase(getAllCara.pending, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  });

  builder.addCase(getAllCara.fulfilled, (state, action) => {
    return {
      ...state,
      cara: [...action.payload],
    };
  });

  builder.addCase(getAllCara.rejected, (state, action) => {
    return {
      ...state,
      loading: false,
      isInitialized: false,
    };
  });

  builder.addCase(updateAll.pending, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  });

  builder.addCase(updateAll.fulfilled, (state, action) => {
    window.location.reload(false);

    return {
      ...state,
      cara: [...action.payload],
    };
  });

  builder.addCase(updateAll.rejected, (state, action) => {
    return {
      ...state,
      loading: false,
      isInitialized: false,
    };
  });
}

const projectSlice = createSlice({
  name: "project",
  initialState,
  extraReducers,
  reducers: {
    setFloorChoice: (state, action) => ({
      ...state,
      floorChoice: action.payload,
      searchData: action.payload === "all"
          ? [...state.tenants]
          : state.tenants.filter(u => u.unit.toString() === action.payload.toString())
  }),
    resetData: (state, action) => ({
      ...state,
      floorChoice: "all",
      searchData: state.tenants && [...state.tenants],
    }),

    selectFilter: (state, action) => ({
      ...state,
      selectedCategories: action.payload,
      searchData:
        state.tenants &&
        state.tenants.filter(
          (unit) =>
            (unit.categories && unit.categories.includes(action.payload)) ||
            (unit.amenities && unit.amenities.includes(action.payload))
        ),
    }),
  },
});

// Action creators are generated for each case reducer function
export const { setFloorChoice, resetData, selectFilter } = projectSlice.actions;

export default projectSlice.reducer;
