import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectRoom, setFloor } from "../../redux/slices/floorSlice";
import { transform } from "@babel/standalone";
import LoadingComponent from "../LoadingComps/LoadingComponent";
import {motion} from 'framer-motion'
//STYLED COMPONENTS
import {
  CardBottom,
  CardContainer,
  CardMainPic,
  CardTwoBlueLabel,
  CardTwoBottom,
  CardTwoBottomSuite,
  CardTwoInfoContainer,
  CardContainer3,
  CardMainPic3,
  CardRight3,
  Card3SuiteData,
  TitleCard3,
  EditorCardView,
  TitleScreen,
  SubTitleScreen,
} from "./Cards.styled";
import { changeSvg } from "../../redux/slices/themeSlice";

export const CardOne = ({ values }) => {
  const dispatch = useDispatch();

  const clickCardFunc = (floor, suite) => {
    dispatch(setFloor(floor.toString()));
    dispatch(selectRoom(suite));
  };

  const screenReducer = useSelector((state) => state.screenReducer);
  const { themeData } = screenReducer;

  const floor = useSelector((state) => state.floor);
  const { floorNum, number } = floor;

  return (
    <CardContainer
      style={{
        background:
          number === values.suite && themeData && themeData.cardBgColor
            ? themeData.cardBgColor
            : "white",
        padding: "5px",
        color: "white",
      }}
      onClick={() => clickCardFunc(values.floor, values.suite)}
    >
      <CardMainPic>
        {values.logo ? (
          <img
            src={values.logo}
            style={{ maxWidth: "80%", maxHeight: "10.5vh", padding: ".5rem" }}
            alt="ImageCard"
          />
        ) : (
          <h1
            style={{
              color: "black",
              cardFontFamily:
                themeData &&
                themeData.cardFontFamily &&
                themeData.cardFontFamily,
              fontSize:
                themeData && themeData.cardFontSize && themeData.cardFontSize,
            }}
          >
            {values.companyName}
          </h1>
        )}
      </CardMainPic>

      <CardBottom>
        <CardTwoBlueLabel
          style={{
            background:
              number === values.suite && themeData && themeData.cardBgColor
                ? themeData.cardBgColor
                : "white",
            color:
              number === values.suite && themeData && themeData.cardColor
                ? themeData.cardColor
                : "black",
          }}
        >
          {values.companyName}
        </CardTwoBlueLabel>
        <CardTwoInfoContainer>
          <CardTwoBottom
            style={{
              color:
                number === values.suite && themeData && themeData.cardColor
                  ? themeData.cardColor
                  : "black",
              backgroundColor:
                number === values.suite && themeData && themeData.cardBgColor
                  ? themeData.cardBgColor
                  : "white",
            }}
          >
            {values.floor.toString() === "0"
              ? "Lower Level"
              : `Floor ${values.floor}`}
          </CardTwoBottom>
          <CardTwoBottomSuite
            style={{
              color:
                number === values.suite && themeData && themeData.cardColor
                  ? themeData.cardColor
                  : "black",
              backgroundColor:
                number === values.suite && themeData && themeData.cardBgColor
                  ? themeData.cardBgColor
                  : "white",
            }}
          >
            {" "}
            Suite {values.suite}
          </CardTwoBottomSuite>
        </CardTwoInfoContainer>
      </CardBottom>
    </CardContainer>
  );
};

export const CardTwo = ({ values }) => {
  const dispatch = useDispatch();

  const clickCardFunc = (floor, suite) => {
    dispatch(setFloor(floor.toString()));
    dispatch(selectRoom(suite));
  };

  const screenReducer = useSelector((state) => state.screenReducer);
  const { themeData } = screenReducer;

  const floor = useSelector((state) => state.floor);
  const { floorNum, number } = floor;
  return (
    <CardContainer
      style={{
        background:
          number === values.suite && themeData && themeData.cardBgColor
            ? themeData.cardBgColor
            : "white",
        padding: "5px",
        color: "white",
      }}
      onClick={() => clickCardFunc(values.floor, values.suite)}
    >
      <CardMainPic>
        {values.logo ? (
          <img
            src={values.logo}
            style={{ maxWidth: "80%", maxHeight: "10.5vh", padding: "1rem" }}
            alt="ImageCard"
          />
        ) : (
          <h1
            style={{
              color: "black",
              cardFontFamily:
                themeData &&
                themeData.cardFontFamily &&
                themeData.cardFontFamily,
              fontSize:
                themeData && themeData.cardFontSize && themeData.cardFontSize,
            }}
          >
            {values.companyName}
          </h1>
        )}
      </CardMainPic>

      <CardBottom>
        <CardTwoBlueLabel
          style={{
            background:
              number === values.suite && themeData && themeData.cardBgColor
                ? themeData.cardBgColor
                : "white",
            color:
              number === values.suite && themeData && themeData.cardColor
                ? themeData.cardColor
                : "black",
          }}
        >
          {values.companyName}
        </CardTwoBlueLabel>
        <CardTwoInfoContainer>
          <CardTwoBottom
            style={{
              color:
                number === values.suite && themeData && themeData.cardColor
                  ? themeData.cardColor
                  : "black",
              backgroundColor:
                number === values.suite && themeData && themeData.cardBgColor
                  ? themeData.cardBgColor
                  : "white",
            }}
          >
            {values.floor.toString() === "0"
              ? "Lower Level"
              : `Floor ${values.floor}`}
          </CardTwoBottom>
          {/* <CardTwoBottomSuite style={{color:number === values.suite && themeData &&  themeData.cardColor ? themeData.cardColor:"black",backgroundColor:number === values.suite && themeData &&  themeData.cardBgColor ? themeData.cardBgColor:"white",}}> Suite {values.suite}</CardTwoBottomSuite> */}
        </CardTwoInfoContainer>
      </CardBottom>
    </CardContainer>
  );
};

export const CardThree = ({ values, horizontal }) => {
  const dispatch = useDispatch();

  const clickCardFunc = (floor, suite) => {
    dispatch(setFloor(floor.toString()));
    dispatch(selectRoom(suite));
  };

  const screenReducer = useSelector((state) => state.screenReducer);
  const { themeData } = screenReducer;
  const floor = useSelector((state) => state.floor);
  const { floorNum, number } = floor;
  return (
    <CardContainer3
      horizontal={horizontal}
      style={{
        border:
          number === values.suite && themeData && themeData.cardBgColor
            ? `solid 4px ${themeData.cardBgColor}`
            : "",
      }}
      onClick={() => clickCardFunc(values.floor, values.suite)}
    >
      <CardMainPic3>
        {values.logo ? (
          <img
            src={values.logo}
            style={{
              maxWidth: "100%",
              height: "100%",
              bordeRadius: "2.243px",
              padding: "10px",
              objectFit: "contain",
            }}
            alt="ImageCard"
          />
        ) : (
          <div
            style={{
              cardFontFamily:
                themeData &&
                themeData.cardFontFamily &&
                themeData.cardFontFamily,
              fontSize:
                themeData && themeData.cardFontSize && themeData.cardFontSize,
            }}
          >
            {values.companyName}
          </div>
        )}
      </CardMainPic3>

      <CardRight3>
        <TitleCard3 style={{ paddingTop: "10px" }}>
          <div>{values.companyName}</div>
        </TitleCard3>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            flex: 1,
          }}
        >
          <Card3SuiteData
            style={{
              background:
                number === values.suite &&
                themeData &&
                themeData.cardBgColor &&
                `${themeData.cardBgColor}`,
              color:
                number === values.suite &&
                themeData &&
                themeData.cardColor &&
                `${themeData.cardColor}`,
            }}
          >
            <div>
              {values.floor !== "0" && "Floor: "}
              {values.floor === "0" ? "Lower level" : values.floor}
            </div>
          </Card3SuiteData>
          <Card3SuiteData
            style={{
              background:
                number === values.suite &&
                themeData &&
                themeData.cardBgColor &&
                `${themeData.cardBgColor}`,
              color:
                number === values.suite &&
                themeData &&
                themeData.cardColor &&
                `${themeData.cardColor}`,
            }}
          >
            <div>
              Suite:
              {values.suite}
            </div>
          </Card3SuiteData>
        </div>
      </CardRight3>
    </CardContainer3>
  );
};

export const CardSeaty = ({ values, horizontal }) => {
  const dispatch = useDispatch();

  const clickCardFunc = (floor, suite) => {
    dispatch(setFloor(floor.toString()));
    dispatch(selectRoom(suite));
  };

  const screenReducer = useSelector((state) => state.screenReducer);
  const { themeData } = screenReducer;

  const floor = useSelector((state) => state.floor);
  const { floorNum, number } = floor;
  return (
    <EditorCardView
      style={{
        backgroundImage: `url(${
          themeData && themeData.cardPic && themeData.cardPic
        })`,
        backgroundSize: "contain",
        margin: "0px 18px 18px 18px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          justifyContent: "center",
        }}
      >
        <TitleScreen themeData={themeData}>{values.name}</TitleScreen>
        <SubTitleScreen themeData={themeData}>
          Table {values.unit}
        </SubTitleScreen>
      </div>
    </EditorCardView>
  );
};


const DEVICE_TYPE_TO_VAL = {
  'Mobile': 'mobile',
  'Mobile Landscape': 'mobileLand',
  'Tablet': 'tablet',
  'Tablet Landscape': 'baseDesk',
  'Vertical': 'kioskVert',
  'Horizontal': 'kioskHor'
};

const CARD_VARIANTS = {
  offscreen: (index) => ({
    opacity: .7,
    scale: 0.7,
    y: -50,
    transition: {
      delay: index * 1,
    },
  }),
  onscreen: {
    scale: 1,
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: .4,
    }
  },
  exit: {
    scale: 0.7,
    opacity: .7,
    y: -50,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: .3,
    }
  }
};

const transpiledCodeCache = {};

const getTranspiledCode = (text) => {
  if (!transpiledCodeCache[text]) {
    transpiledCodeCache[text] = transform(text, { presets: ["react"] }).code;
  }
  return transpiledCodeCache[text];
}

const SeatyCodeComponent = ({ values, deviceType }) => {
  const dispatch = useDispatch();
  const { themeData } = useSelector(state => state.screenReducer);
  const { svgChoice } = useSelector(state => state.themeReducer);

  const val = DEVICE_TYPE_TO_VAL[deviceType] || 'notSupported';
  const text2 = useMemo(() => themeData && themeData[`${val}CardTitleStyle`], [themeData, val]);

  const setCurrentFunc = useCallback(value => {
    dispatch(changeSvg(value));
  }, [dispatch]);

  const [DynamicContent, setDynamicContent] = useState(null);
  const evaluatedJSXCache = useRef({});

  const cacheKey = useMemo(() => `${text2}_${values.unit}_${values._id}_${themeData.cardPic}_${svgChoice._id}`, 
    [text2, values.unit, values._id, themeData.cardPic, svgChoice._id]);

  useEffect(() => {
    if (!evaluatedJSXCache.current[cacheKey]) {
      const transpiledCode = getTranspiledCode(text2);
      const Func = new Function("React", "values", "themeData", "setCurrentFunc", "svgChoice", `return ${transpiledCode};`);
      const content = Func(React, values, themeData, setCurrentFunc, svgChoice);
      evaluatedJSXCache.current[cacheKey] = content;
    }
    setDynamicContent(() => evaluatedJSXCache.current[cacheKey]);
  }, [cacheKey, text2, setCurrentFunc, values, svgChoice, themeData]);

  return (
    <motion.div variants={CARD_VARIANTS} style={{padding:'0px'}}>
      {DynamicContent || <LoadingComponent />}
    </motion.div>
  );
};

export const SeatyCode = React.memo(SeatyCodeComponent);