import {useState, useRef} from 'react'
import Keyboard from "react-simple-keyboard";


import "react-simple-keyboard/build/css/index.css";
import "./styles.css";



const KeyboardComp = ({keyboardSearchFunc, goFunction}) => {
    const [input, setInput] = useState("");
    const [layout, setLayout] = useState("default");
    const keyboard = useRef();
  
    const onChange = input => {
      setInput(input);
      keyboardSearchFunc(input)
    };
  
    const handleShift = () => {
      const newLayoutName = layout === "default" ? "shift" : "default";
      setLayout(newLayoutName);
    };
  


    const onKeyPress = (button) => {

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") handleShift();
      if(button === "{GO}"){
        setTimeout(() => {
          goFunction()
      }, 400); // Adjust the delay as needed

      }
    };
  
    const onChangeInput = event => {
      const input = event.target.value;
      setInput(input);
      keyboard.current.setInput(input);
    };
  return (
    <div style={{display:'flex',justifyContent:'center',width:"100%",height:'auto'}}>

      <Keyboard
        keyboardRef={r => (keyboard.current = r)}
        layoutName={layout}
        onChange={onChange}
        onKeyPress={onKeyPress}


        layout={ {
          'default': [
            '1 2 3 4 5 6 7 8 9 0 {bksp}',
            'q w e r t y u i o p',
            'a s d f g h j k l',
            'z x c v b n m',
            '{space} {GO}'
          ]
        }}

        display={{
          "{GO}": "Go",
          "{escape}": "esc ⎋",
          "{tab}": "tab ⇥",
          "{bksp}": "backspace ⌫",
          "{enter}": "enter ↵",
          "{capslock}": "caps lock ⇪",
          "{shiftleft}": "shift ⇧",
          "{shiftright}": "shift ⇧",
          "{controlleft}": "ctrl ⌃",
          "{controlright}": "ctrl ⌃",
          "{altleft}": "alt ⌥",
          "{altright}": "alt ⌥",
          "{metaleft}": "cmd ⌘",
          "{metaright}": "cmd ⌘",
          "{space}":"space"
        }}
        
        
      />
    </div>
  )




}

export default KeyboardComp