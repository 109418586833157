import styled from "styled-components";

export const WeatherContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 1vw;
  -webkit-box-align: center;
  align-items: center;
  background: rgb(255, 255, 255);
  padding: 0px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 4px 30px;
  backdrop-filter: blur(5.6px);
  margin: 0;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  color: #e9e7ee;
`;

export const WeatherCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0vh 0vh;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  background: black;
`;

export const WeatherCardMain = styled.div`
  width: 100%;
  max-width: 1vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: ${(props) => (props.horizontal ? "28rem" : "100%")};
  background: black;
`;

///WEATHER CARD TWO

export const WeatherContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: 1.01px solid rgba(189, 189, 189, 0.5);
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 35.37356948852539px 73.5770263671875px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20.533918380737305px);
  color: #141918;
  font-family: Montserrat;
  font-style: normal;

`;

export const Weather2Top = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex: 0.8;
`;


export const Weather2TopLeft = styled.div`
  height: 100%;
  width: 100%;
`;


export const Weather2TopRight = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const Weather2Bottom = styled.div`
  height: 100%;
  width: 100%;

  overflow: hidden;
  flex: 1;
`;

export const WeatherCard2 = styled.div`
  width: 100%;
  overflow: hidden;
  border: none;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  padding-bottom:40px;
`;


export const WeatherInfo = styled.p`
 color: #141918;
font-family: Montserrat;
font-size: 14.702px;
font-style: normal;
font-weight: 300;
line-height: normal;
`;

