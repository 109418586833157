import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";

import LoginScreen from "./Pages/LoginScreen";
import {
  changeSvg,
  onlineChange,
  resetSearchFunc,
} from "./redux/slices/themeSlice.jsx";

import  { Toaster } from "react-hot-toast";

import { HeaderCustomCode } from "./Components/Headers/HeaderCustomCode";
import { FooterCustomCode } from "./Components/Footers/FooterCustomCode";
import FlipContainer from "./Components/FlipPhone/FlipContainer";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,

} from "react-router-dom";

import {
  AppContainer,
  MainAppContainer,
  MenuContainer,
  MenuBottomContainer,
  NavTopCard,
  MainAppWrapper,
} from "./App.styled";

//redux slice imports
import { resetRooms } from "./redux/slices/floorSlice";

import Home from "./Pages/Home";

import mainLogo from "./Assets/signLogo.webp";
import {
  resetData,
} from "./redux/slices/screenSlice";
import { changeOrientation } from "./redux/slices/themeSlice";
import NotSupported from "./Components/NotSupported/NotSupported";

const isMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(
    userAgent
  );
};

const isTabletDevice = () => {
  // Define a basic tablet device check based on popular user agents
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /ipad|android|tablet|touch/i.test(userAgent) && !isMobileDevice();
};

const determineDeviceType = (width, height) => {
  // Check for standard resolutions
  if (width === 1080 && height === 1920) return "Vertical";
  if (width === 1920 && height === 1080) return "Horizontal";

  // Directly exclude resolutions higher than 1920x1080
  if (width > 1920 || height > 1920) {
    return "Not Supported";
  }

  // Check for mobile devices
  if (isMobileDevice()) {
    if (height > width) return "Mobile";
    if (width > height) return "Flip";
  }

  // Check for tablet devices
  if (isTabletDevice()) {
    if (height > width) return "Tablet";
    if (width > height) return "Flip";
  }

  // If none of the above conditions are met, return "Not Supported"
  return "Not Supported";
};

const App = ({ width, height, deviceType, gallery, svg }) => {
  //socket and watch mongodb
  
  const [zoomReset, setZoomReset] = useState();

  

  //Initial setup for zoom
  const resetTheZoomFunc = (val) => {
    if (val) {
      setZoomReset(val);
    }
  };

  //resetFunc
  const resetTheZoomMain = () => {
    if (zoomReset) {
      const { resetTransform } = zoomReset;
      resetTransform();

      dispatch(changeSvg(""));
    }
  };

  //Disable right click

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }, []);

  ///Refresh fix

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }

    function handleOffline() {
      setIsOnline(false);
    }

    function preventRefresh(e) {
      if (!isOnline) {
        e.preventDefault();
        e.returnValue = "";
      }
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    window.addEventListener("beforeunload", preventRefresh);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("beforeunload", preventRefresh);
    };
  }, [isOnline]);

  useEffect(() => {
    dispatch(onlineChange(isOnline));
  }, [isOnline]);

  //dispatch
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (access.length > 0) {
  //     dispatch(getTheme(access.length > 0 && access[0].project._id));
  //   }
  // }, [access]);

  //RESIZE

  //IDLE TIME
  const [horizontal, setHorizontal] = useState(false);

  useEffect(() => {
    const setVhProperty = () => {
      const vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setVhProperty();
    window.addEventListener("resize", setVhProperty);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", setVhProperty);
    };
  }, [horizontal]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerHeight > window.innerWidth) {
        setHorizontal(false);
      } else {
        setHorizontal(true);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [state, setState] = useState("Active");
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);

  const onIdle = () => {
    setState("Idle");
    dispatch(resetRooms());
    dispatch(resetData());
    dispatch(resetSearchFunc(true));
    resetTheZoomMain();
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 5_000,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);
    return () => {
      clearInterval(interval);
    };
  });

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path={`/`} element={<Root />}>
        <Route
          index
          element={
            <Home
              resetTheZoom={(val) => resetTheZoomFunc(val)}
              horizontal={horizontal}
              state={state}
              width={width}
              height={height}
              deviceType={deviceType}
              gallery={gallery}
              svg={svg}
            />
          }
        />
        <Route path={`:loginId`} element={<LoginScreen />} />
      </Route>
    )
  );

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;

//ROOT FOR REACT ROUTER
const Root = () => {
  const { access, projects, themeData } = useSelector(
    (state) => state.screenReducer
  );
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [deviceType, setDeviceType] = useState("");
  const [gallery, setGallery] = useState(false);
  const [svg, setSvg] = useState(false);
  const [horizontal, setHorizontal] = useState(false);

  const handleResize = useCallback(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);

    setDeviceType(determineDeviceType(window.innerWidth, window.innerHeight));

    switch (projects?.[0]?.planType) {
      case "Silver":
        setGallery(false);
        setSvg(false);
        break;
      case "Gold":
        setGallery(true);
        setSvg(false);
        break;
      case "Platinum":
        setGallery(true);
        setSvg(true);
        break;
      default:
        setGallery(false);
        setSvg(false);
    }

    setHorizontal(window.innerHeight <= window.innerWidth);
  }, [projects]);

  useEffect(() => {
    handleResize(); // Initial call for setup
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const newData = {
      deviceType: deviceType,
      horizontal: horizontal,
      svg: svg,
      gallery: gallery,
    };
    dispatch(changeOrientation(newData));
    localStorage.setItem("orientation", JSON.stringify(newData));
  }, [deviceType]);

  return (
    <MainAppWrapper deviceType={deviceType} themeData={themeData}>
      <Toaster position="top-center" />
      {access && access.length > 0 ? (
        <>
         {deviceType === 'Flip' ?            <div
              style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                backgroundColor: "white",
              }}
            >
              <FlipContainer />
            </div>: deviceType ==='Horizontal' || deviceType === "Not Supported" ?    <div
              style={{
                color: "black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                backgroundColor: "white",
                fontSize:'4vw'
              }}
            >
              <NotSupported/>
            </div>:<AppContainer
              themeData={themeData}
              template={themeData && themeData.template}
              horizontal={horizontal}
              deviceType={deviceType}
            >
              <MenuContainer
                horizontal
                template={themeData && themeData.template}
              >
                {themeData && themeData.template !== 3 ? (
                  <NavTopCard
                    style={{
                      background: themeData && themeData.headerBgColor,
                      color: themeData && themeData.headerColor,
                    }}
                  >
                    <img
                      src={
                        themeData && themeData.headerLogo
                          ? themeData.headerLogo
                          : mainLogo
                      }
                      style={{ maxHeight: "85%" }}
                      alt={"SignCast"}
                    />
                  </NavTopCard>
                ) : (
                  <HeaderCustomCode
                    projects={{ projects }}
                    deviceType={deviceType}
                  />
                )}
              </MenuContainer>

              <MainAppContainer>
                <Outlet
                  width={width}
                  height={height}
                  deviceType={deviceType}
                  gallery={gallery}
                  svg={svg}
                />
              </MainAppContainer>

              <MenuBottomContainer themeData={themeData}>
                <FooterCustomCode
                  projects={{ projects }}
                  deviceType={deviceType}
                />
              </MenuBottomContainer>
            </AppContainer>}
        </>
      ) : (
        <>
          <LoginScreen />
        </>
      )}
    </MainAppWrapper>
  );
};
