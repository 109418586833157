import { configureStore } from '@reduxjs/toolkit'

import authReducer from './slices/authSlice'
import floorReducer from './slices/floorSlice'
import projectReducer from './slices/projectSlice'
import screenReducer from './slices/screenSlice'
import themeReducer from './slices/themeSlice'


export const store = configureStore({
  reducer: {
    auth: authReducer,
    floor:floorReducer,
    projectReducer,
    screenReducer,
    themeReducer
  },
})