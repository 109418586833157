// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


// ** Axios Imports
import axios from "axios";

/*=============================================
=            ASYNC THUNK FUNCTIONS            =
=============================================*/

/*=============================================
=                INITIAL STATES              =
=============================================*/

// ** Fetch Authentication
let token = localStorage.getItem("token");
let userData = null;
if (token) {
  const payload = JSON.parse(window.atob(token.split(".")[1]));
  if (payload.exp < Date.now() / 1000) {
    localStorage.removeItem("token");
    token = null;
  } else {
    userData = payload.user;
  }
}

const initialState = {
  loading: false,
  userInfo: userData, // for user object
  userToken: null, // for storing the JWT
  error: null,
  success: false,
  editAdmin:false // for monitoring the registration process.
};

const options = {
  method: "POST",
  headers: { "Content-Type": "application/json" },
};



// ** FETCH DEAL MEMO LIST
export const handleLogin = createAsyncThunk(
    "login-user",
    async ({email, password }) => {
      const res = await axios.post("/api/users/login/", {
        email,
        password,
      });
      localStorage.setItem("token", res.data);
      return res.data;
    }
  );
  



// ** FETCH DEAL MEMO LIST
export const handleRegister = createAsyncThunk(
  "register-user",
  async ({ name, email, password }) => {
    const res = await axios.post("/api/users/signup/", {
      name,
      email,
      password,
    });
    localStorage.setItem("token", res.data);
    return res.data;
  }
);

/*=====  End of ASYNC THINK FUNCTIONS  ======*/

/*============================================
=             REGISTER EXTRA REDUCERS              =
=============================================*/
function extraReducers(builder) {
  builder.addCase(handleRegister.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(handleRegister.fulfilled, (state, action) => {
    let token = action.payload;
    let userData = null;
    if (token) {
      const payload = JSON.parse(window.atob(token.split(".")[1]));
      userData = payload.user;
    }
    return {
      ...state,
      loading: false,
      userToken: action.payload,
      userInfo:userData
    };
  });
  builder.addCase(handleRegister.rejected, (state, action) => {
    return {
      ...state,
      loading: false,
      isInitialized: false,
    };
  });
   builder.addCase(handleLogin.pending, (state, action) => {
      return {
        ...state,
        loading: true,
        isInitialized: true,
      };
    });
    builder.addCase(handleLogin.fulfilled, (state, action) => {
      let token = action.payload;
      let userData = null;
      if (token) {
        const payload = JSON.parse(window.atob(token.split(".")[1]));
  
        userData = payload.user;
        
      }
      return {
        ...state,
        loading: false,
        userToken: action.payload,
        userInfo:userData
      };
    });
    builder.addCase(handleLogin.rejected, (state, action) => {
      return {
        ...state,
        loading: false,
        isInitialized: false,
      };
    });
}






export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => ({
        loading: false,
        userInfo: null, // for user object
        userToken: null, // for storing the JWT
        error: null,
        success: false, // for
    }),
    setUser: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    editAdminSlice: (state, action) => ({
      ...state,
     editAdmin: !state.editAdmin,
    }),
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const { logout, setUser,editAdminSlice } = authSlice.actions;

export default authSlice.reducer;
