import styled from "styled-components";

export const FilterContainer = styled.div`
  height: ${(props) => !props.horizontal && "100%"};
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0px;
  /* background-color: black; */
  color: white;
  border-bottom: ${(props) => (props.horizontal ? "white 10px solid" : "none")};
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px; */
`;

export const FilterInput = styled.input`
  width: 100%;
  height: 100%;
  max-width: 35vw;
  max-height: 2vh;
  font-size: 2.5vw;
  color: white;
  border-bottom: solid 2px navy;
`;

export const FilterButton = styled.button`
  color: white;
  background-color: rgb(0, 0, 0);
  border-radius: none;
  font-size: 2rem;
  max-width: 30vw;
  height: 2vh;
  height: ${(props) => (props.horizontal === true ? "4rem" : "2vh")};
  border: none;
`;

export const FilterContainer2 = styled.div`
  height: ${(props) => !props.horizontal && "100%"};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  background: rgba(178, 178, 178, 0.5);
  box-shadow: 0px 19.080080032348633px 39.68656539916992px 0px
    rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(11.0757474899292px);
  color: white;
  border-bottom: ${(props) => (props.horizontal ? "white 10px solid" : "none")};
  /* padding:.2rem .2rem .8rem .2rem ; */
  /* padding:5px; */
`;

export const SearchInputContainer = styled.div`
  height: 90%;
  width: 100%;
  border-radius: 5px;
  background: rgba(86, 86, 86, 0.99);
  box-shadow: 0px 19.080080032348633px 39.68656539916992px 0px
    rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(11.0757474899292px);
  display: flex;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  align-items: center;
`;
export const SearchInput2 = styled.input`
  color: white;
  border-radius: 10px 0px 0px 10px;
  background: transparent;
  box-shadow: 0px 19.080080032348633px 39.68656539916992px 0px
    rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(11.0757474899292px);
  user-select: none !important;
`;

export const FilterWord = styled.p`
color: #FFF;
font-family: Raleway;
font-size: ${props => props.deviceType === 'Vertical' ? '22px' : '12px'};
/* font-size: 22px; */
font-style: normal;
font-weight: 600;
line-height: normal;
`;
// export const FilterInput2 = styled.input`
//   width: 100%;
//   height: 100%;
//   max-width: 35vw;
//   max-height: 2vh;
//   font-size: 2.5vw;
//   color:white;
//   border-bottom:solid 2px navy;
// `;

// export const FilterButton2 = styled.button`
//   color:white;
//   background-color: rgb(0, 0, 0);
//   border-radius: none;
//   font-size: 2rem;
//   max-width: 30vw;
//   height: 2vh;
// height: ${props=>props.horizontal === true ? '4rem':'2vh'};
//   border: 5px;

// `;

export const SeatyFilterContainer2 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: rgba(178, 178, 178, 0.50); */
  border-radius: 0.3125rem 0rem 0rem 0.3125rem;
  border-radius: 0.3125rem 0rem 0rem 0.3125rem;
`;

export const SeatySearchInputContainer = styled.div`
height: ${props => props.deviceType === 'Vertical' ? '100%' : '80%'};
  width: 100%;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 19.080080032348633px 39.68656539916992px 0px
    rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  padding-left: 0.5rem;
  /* padding-right: 0.5rem; */
  align-items: center;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 1.88651px 0px rgba(0, 0, 0, 0.10), 0px 0px 3.77301px 0px rgba(0, 0, 0, 0.09), 0px 0px 5.03068px 0px rgba(0, 0, 0, 0.05), 0px 0px 5.65952px 0px rgba(0, 0, 0, 0.01), 0px 0px 6.28835px 0px rgba(0, 0, 0, 0.00);
`;

export const SeatySearchInput2 = styled.input`
  color: white;
  border-radius: 10px 0px 0px 10px;
  background: transparent;

  font-family: Montserrat;
  font-size: 0.94325rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #141918;

font-family: Raleway;
font-size: 24px;
font-size: ${props => props.deviceType === 'Vertical' ? '24px' : '15px'};
font-style: normal;
font-weight: 400;
line-height: normal;
/* 
  user-select: none !important; */
`;

export const SeatyFilterButton = styled.div`

  width: ${props => props.deviceType === 'Vertical' ? '140px' : '98px'};
  height: 100%;
  flex-shrink: 0;
  background: rgba(84, 84, 84, 0.9);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 0px 1.88651px 0px rgba(0, 0, 0, 0.1),
    0px 0px 3.77301px 0px rgba(0, 0, 0, 0.09),
    0px 0px 5.03068px 0px rgba(0, 0, 0, 0.05),
    0px 0px 5.65952px 0px rgba(0, 0, 0, 0.01),
    0px 0px 6.28835px 0px rgba(0, 0, 0, 0);
  color: #fff;
  font-family: Montserrat;
  font-size: 0.75963rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:.1rem;
  cursor: pointer;
`;

export const SeatyResetButton = styled.div`
display: flex;
justify-content: center;
align-items: center;

   width: ${props => props.deviceType === 'Vertical' ? '125px' : '68px'};
    height: 100%;
  flex-shrink: 0;
  border-radius: 0rem 0.3125rem 0.3125rem 0rem;
  background: rgba(62, 62, 62, 0.9);
  color: #FFF;
font-family: Montserrat;

font-style: normal;
cursor: pointer;
font-weight: 600;
line-height: normal;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 0px 1.88651px 0px rgba(0, 0, 0, 0),
    0px 0px 3.77301px 0px rgba(0, 0, 0, 0),
    0px 0px 5.03068px 0px rgba(0, 0, 0, 0),
    0px 0px 5.65952px 0px rgba(0, 0, 0, 0),
    0px 0px 6.28835px 0px rgba(0, 0, 0, 0);
`;
