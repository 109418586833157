import styled from 'styled-components'
import {motion} from 'framer-motion'


export const CardLoaderContainer = styled(motion.div)`
    display: flex;
width: 93%;
height: 142px;
padding: 25px 22px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 16px;
flex-shrink: 0;
border-radius: 8px;
background: #FFF;
box-shadow: 0px 8px 32px 0px rgba(17, 17, 26, 0.05), 0px 4px 16px 0px rgba(17, 17, 26, 0.05);
margin:8px 8px 0px 8px;
`


