
import styled, { keyframes } from 'styled-components';


const slide = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const CardContainer = styled.div`
  margin: 5px;
  width: 45.3%;
  max-height: 100%;
  justify-content: center;
  background: #fffefe;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const CardMainPic = styled.div`
  flex: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  height: 100%;
`;

export const CardBlueLabel = styled.div`
  width: 100%;
  color: white;
  flex: 0.3;
`;

export const CardBottom = styled.div`
  flex: 0.5;
  font-weight: 700;
  width: 100%;
  min-height: 3vh;
  max-height: 3vh;
  color: #1258a8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px navy;
`;

export const CardBottomSuite = styled.div`
  flex: 0.5;
  font-size: 1.5vw;
  font-weight: 700;
`;

//CARD TWO


export const CardTwoContainer = styled.div`
  margin: 5px;
  padding: 5px;
  width: 45vw;
  height: 12vw;
  background: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

export const CardTwoMainPic = styled.img`
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 4vw;
`;

export const CardTwoBlueLabel = styled.div`
  width: 100%;
  flex: 2;
  height: 100%;
  font-size: .8rem;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 1rem;
`;

export const CardTwoBottom = styled.div`
  font-size: .7rem;
  font-weight: 700;
  color:black;

`;

export const CardTwoBottomSuite = styled.div`
  font-size: .7rem;
  font-weight: 700;
  color: black;
`;

export const CardTwoInfoContainer = styled.div`
  display: flex;
  flex: 0.4;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;



//CARD THREE




export const CardContainer3 = styled.div`
display: flex;
min-width:350px;
width: ${props=>props.horizontal === true ? '296px':'336px'};
height: ${props=>props.horizontal === true ? '134px':'158px'};
flex-shrink: 0;
border-radius: 5px;
background: #FFF;
padding:5px;
cursor: pointer;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const CardMainPic3 = styled.div`
flex:1;
display: flex;
justify-content: center;
align-items: center;
flex-shrink: 0;
overflow: hidden;
border: 1.839px solid #F6F6F6;
background: #FFF;
width: 100%;
height: 100%;

flex-shrink: 0;
`;

export const CardBlueLabel3 = styled.div`
  width: 100%;
  flex: 2;
  height: 100%;
  font-size: .8rem;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 1rem;
`;

export const CardBottom3 = styled.div`
  font-size: .7rem;
  font-weight: 700;
  color:black;

`;

export const CardBottomSuite3 = styled.div`
  font-size: .7rem;
  font-weight: 700;
  color: black;
`;

export const CardInfoContainer3 = styled.div`
  display: flex;
  flex: 0.4;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;


export const CardRight3 = styled.div`
flex:1;
display:flex;
flex-direction: column;
/* justify-content:center; */
align-items: center;
position: relative;
`

export const Card3Title = styled.div`
color: #0E0E0E;
text-align: center;
font-family: Montserrat;
font-size: 15.596px;
font-style: normal;
font-weight: 700;
line-height: normal;
position:absolute;
top:30px;
`

export const Card3SuiteData = styled.div`
display: flex;
width: 127.859px;
padding: 5.352px 9.366px;
justify-content: center;
align-items: flex-start;
gap: 5.352px;
border-radius: 2.243px;
background: #F6F6F6;

`



export const TitleCard3 = styled.div`
width: 140.202px;
height: 34.412px;
flex-shrink: 0;
  color: #0C2A95;
text-align: center;
font-family: Montserrat;
font-size: 13.635px;
font-style: normal;
font-weight: 500;
line-height: normal;
flex:1;
display:flex;
justify-content:center;
align-items:center;
`


export const EditorCardView = styled.div`
/* min-width: 350px; */
    position: relative;
    /* max-width:30.8vw; */
    max-width:400px;
    min-width: 300px; 
    width: 100%;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 166 / 63;
    margin: 0rem;
    display: flex;
    padding:32px;
    /* overflow: hidden; */
  white-space: wrap;
  width: 100%;

    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
`;


///MEDIA QUERY 

export const TitleScreen = styled.div`
/* Default Mobile Styles */
  color: ${props => props.themeData?.mobileCardTitleStyle?.color || 'inherit'};
  font-family: ${props => props.themeData?.mobileCardTitleStyle?.fontFamily || 'inherit'};
  font-size: ${props => props.themeData?.mobileCardTitleStyle?.fontSize || 'inherit'};
  font-style: ${props => props.themeData?.mobileCardTitleStyle?.fontStyle || 'normal'};
  font-weight: ${props => props.themeData?.mobileCardTitleStyle?.fontWeight || 'normal'};
  line-height: ${props => props.themeData?.mobileCardTitleStyle?.lineHeight || 'normal'};
  text-align: ${props => props.themeData?.mobileCardTitleStyle?.textAlign || 'center'};
  text-decoration: ${props => props.themeData?.mobileCardTitleStyle?.textDecoration || 'none'};
  white-space: wrap;

  text-overflow:wrap;

  
  
/* Other styles... */

/* Mobile Landscape Styles */
@media (min-width: 480px) {
  color: ${props => props.themeData?.mobileLandCardTitleStyle?.color || 'inherit'};
  font-family: ${props => props.themeData?.mobileLandCardTitleStyle?.fontFamily || 'inherit'};
  font-size: ${props => props.themeData?.mobileLandCardTitleStyle?.fontSize || 'inherit'};
  font-style: ${props => props.themeData?.mobileLandCardTitleStyle?.fontStyle || 'normal'};
  font-weight: ${props => props.themeData?.mobileLandCardTitleStyle?.fontWeight || 'normal'};
  line-height: ${props => props.themeData?.mobileLandCardTitleStyle?.lineHeight || 'normal'};
  text-align: ${props => props.themeData?.mobileLandCardTitleStyle?.textAlign || 'center'};
  text-decoration: ${props => props.themeData?.mobileLandCardTitleStyle?.textDecoration || 'none'};
  /* Other styles... */
}

/* Tablet Styles */
@media (min-width: 768px) {
  color: ${props => props.themeData?.tabletCardTitleStyle?.color || 'inherit'};
  font-family: ${props => props.themeData?.tabletCardTitleStyle?.fontFamily || 'inherit'};
  font-size: ${props => props.themeData?.tabletCardTitleStyle?.fontSize || 'inherit'};
  font-style: ${props => props.themeData?.tabletCardTitleStyle?.fontStyle || 'normal'};
  font-weight: ${props => props.themeData?.tabletCardTitleStyle?.fontWeight || 'normal'};
  line-height: ${props => props.themeData?.tabletCardTitleStyle?.lineHeight || 'normal'};
  text-align: ${props => props.themeData?.tabletCardTitleStyle?.textAlign || 'center'};
  text-decoration: ${props => props.themeData?.tabletCardTitleStyle?.textDecoration || 'none'};
  /* Other styles... */
}

/* Base Desktop Styles */
@media (min-width: 992px) {
  color: ${props => props.themeData?.baseDeskCardTitleStyle?.color || 'inherit'};
  font-family: ${props => props.themeData?.baseDeskCardTitleStyle?.fontFamily || 'inherit'};
  font-size: ${props => props.themeData?.baseDeskCardTitleStyle?.fontSize || 'inherit'};
  font-style: ${props => props.themeData?.baseDeskCardTitleStyle?.fontStyle || 'normal'};
  font-weight: ${props => props.themeData?.baseDeskCardTitleStyle?.fontWeight || 'normal'};
  line-height: ${props => props.themeData?.baseDeskCardTitleStyle?.lineHeight || 'normal'};
  text-align: ${props => props.themeData?.baseDeskCardTitleStyle?.textAlign || 'center'};
  text-decoration: ${props => props.themeData?.baseDeskCardTitleStyle?.textDecoration || 'none'};
  /* Other styles... */
}

/* Horizontal Styles */
@media (min-width: 1920px) {
  color: ${props => props.themeData?.kioskHorCardTitleStyle?.color || 'inherit'};
  font-family: ${props => props.themeData?.kioskHorCardTitleStyle?.fontFamily || 'inherit'};
  font-size: ${props => props.themeData?.kioskHorCardTitleStyle?.fontSize || 'inherit'};
  font-style: ${props => props.themeData?.kioskHorCardTitleStyle?.fontStyle || 'normal'};
  font-weight: ${props => props.themeData?.kioskHorCardTitleStyle?.fontWeight || 'normal'};
  line-height: ${props => props.themeData?.kioskHorCardTitleStyle?.lineHeight || 'normal'};
  text-align: ${props => props.themeData?.kioskHorCardTitleStyle?.textAlign || 'center'};
  text-decoration: ${props => props.themeData?.kioskHorCardTitleStyle?.textDecoration || 'none'};
  /* Other styles... */
}

/* Vertical Styles */
@media (min-width: 1080px) and (orientation: portrait) {
  color: ${props => props.themeData?.kioskVertCardTitleStyle?.color || 'inherit'};
  font-family: ${props => props.themeData?.kioskVertCardTitleStyle?.fontFamily || 'inherit'};
  font-size: ${props => props.themeData?.kioskVertCardTitleStyle?.fontSize || 'inherit'};
  font-style: ${props => props.themeData?.kioskVertCardTitleStyle?.fontStyle || 'normal'};
  font-weight: ${props => props.themeData?.kioskVertCardTitleStyle?.fontWeight || 'normal'};
  line-height: ${props => props.themeData?.kioskVertCardTitleStyle?.lineHeight || 'normal'};
  text-align: ${props => props.themeData?.kioskVertCardTitleStyle?.textAlign || 'center'};
  text-decoration: ${props => props.themeData?.kioskVertCardTitleStyle?.textDecoration || 'none'};
  /* Other styles... */
}

`


//////////////////////////////////////////////SUBTITLE FOR CARD //////////////////////////////////////////

export const SubTitleScreen = styled.div`

  /* Default Mobile Styles */
  color: ${props => props.themeData?.mobileCardSubTitleStyle?.color || 'inherit'};
  font-family: ${props => props.themeData?.mobileCardSubTitleStyle?.fontFamily || 'inherit'};
  font-size: ${props => props.themeData?.mobileCardSubTitleStyle?.fontSize || 'inherit'};
  font-style: ${props => props.themeData?.mobileCardSubTitleStyle?.fontStyle || 'normal'};
  font-weight: ${props => props.themeData?.mobileCardSubTitleStyle?.fontWeight || 'normal'};
  line-height: ${props => props.themeData?.mobileCardSubTitleStyle?.lineHeight || 'normal'};
  text-align: ${props => props.themeData?.mobileCardSubTitleStyle?.textAlign || 'center'};
  text-decoration: ${props => props.themeData?.mobileCardSubTitleStyle?.textDecoration || 'none'};

/* Other styles... */

/* Mobile Landscape Styles */
@media (min-width: 480px) {
  color: ${props => props.themeData?.mobileLandCardSubTitleStyle?.color || 'inherit'};
  font-family: ${props => props.themeData?.mobileLandCardSubTitleStyle?.fontFamily || 'inherit'};
  font-size: ${props => props.themeData?.mobileLandCardSubTitleStyle?.fontSize || 'inherit'};
  font-style: ${props => props.themeData?.mobileLandCardSubTitleStyle?.fontStyle || 'normal'};
  font-weight: ${props => props.themeData?.mobileLandCardSubTitleStyle?.fontWeight || 'normal'};
  line-height: ${props => props.themeData?.mobileLandCardSubTitleStyle?.lineHeight || 'normal'};
  text-align: ${props => props.themeData?.mobileLandCardSubTitleStyle?.textAlign || 'center'};
  text-decoration: ${props => props.themeData?.mobileLandCardSubTitleStyle?.textDecoration || 'none'};
  /* Other styles... */
}

/* Tablet Styles */
@media (min-width: 768px) {
  color: ${props => props.themeData?.tabletCardSubTitleStyle?.color || 'inherit'};
  font-family: ${props => props.themeData?.tabletCardSubTitleStyle?.fontFamily || 'inherit'};
  font-size: ${props => props.themeData?.tabletCardSubTitleStyle?.fontSize || 'inherit'};
  font-style: ${props => props.themeData?.tabletCardSubTitleStyle?.fontStyle || 'normal'};
  font-weight: ${props => props.themeData?.tabletCardSubTitleStyle?.fontWeight || 'normal'};
  line-height: ${props => props.themeData?.tabletCardSubTitleStyle?.lineHeight || 'normal'};
  text-align: ${props => props.themeData?.tabletCardSubTitleStyle?.textAlign || 'center'};
  text-decoration: ${props => props.themeData?.tabletCardSubTitleStyle?.textDecoration || 'none'};
  /* Other styles... */
}

/* Base Desktop Styles */
@media (min-width: 992px) {
  color: ${props => props.themeData?.baseDeskCardSubTitleStyle?.color || 'inherit'};
  font-family: ${props => props.themeData?.baseDeskCardSubTitleStyle?.fontFamily || 'inherit'};
  font-size: ${props => props.themeData?.baseDeskCardSubTitleStyle?.fontSize || 'inherit'};
  font-style: ${props => props.themeData?.baseDeskCardSubTitleStyle?.fontStyle || 'normal'};
  font-weight: ${props => props.themeData?.baseDeskCardSubTitleStyle?.fontWeight || 'normal'};
  line-height: ${props => props.themeData?.baseDeskCardSubTitleStyle?.lineHeight || 'normal'};
  text-align: ${props => props.themeData?.baseDeskCardSubTitleStyle?.textAlign || 'center'};
  text-decoration: ${props => props.themeData?.baseDeskCardSubTitleStyle?.textDecoration || 'none'};
  /* Other styles... */
}

/* Horizontal Styles */
@media (min-width: 1920px) {
  color: ${props => props.themeData?.kioskHorCardSubTitleStyle?.color || 'inherit'};
  font-family: ${props => props.themeData?.kioskHorCardSubTitleStyle?.fontFamily || 'inherit'};
  font-size: ${props => props.themeData?.kioskHorCardSubTitleStyle?.fontSize || 'inherit'};
  font-style: ${props => props.themeData?.kioskHorCardSubTitleStyle?.fontStyle || 'normal'};
  font-weight: ${props => props.themeData?.kioskHorCardSubTitleStyle?.fontWeight || 'normal'};
  line-height: ${props => props.themeData?.kioskHorCardSubTitleStyle?.lineHeight || 'normal'};
  text-align: ${props => props.themeData?.kioskHorCardSubTitleStyle?.textAlign || 'center'};
  text-decoration: ${props => props.themeData?.kioskHorCardSubTitleStyle?.textDecoration || 'none'};
  /* Other styles... */
}

/* Vertical Styles */
@media (min-width: 1080px) and (orientation: portrait) {
  color: ${props => props.themeData?.kioskVertCardSubTitleStyle?.color || 'inherit'};
  font-family: ${props => props.themeData?.kioskVertCardSubTitleStyle?.fontFamily || 'inherit'};
  font-size: ${props => props.themeData?.kioskVertCardSubTitleStyle?.fontSize || 'inherit'};
  font-style: ${props => props.themeData?.kioskVertCardSubTitleStyle?.fontStyle || 'normal'};
  font-weight: ${props => props.themeData?.kioskVertCardSubTitleStyle?.fontWeight || 'normal'};
  line-height: ${props => props.themeData?.kioskVertCardSubTitleStyle?.lineHeight || 'normal'};
  text-align: ${props => props.themeData?.kioskVertCardSubTitleStyle?.textAlign || 'center'};
  text-decoration: ${props => props.themeData?.kioskVertCardSubTitleStyle?.textDecoration || 'none'};
}
  /* Other styles... */
`