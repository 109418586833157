import styled from "styled-components";
import {motion} from 'framer-motion'




export const MenuContainer = styled.div`
  grid-area: menu;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.template === 2 ? "0px" : "10px")};
`;

export const MainAppContainer = styled.div`
  grid-area: main;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;
`;

export const MenuBottomContainer = styled.div`
  grid-area: botmenu;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.template === 2 ? "0px" : "10px")};
`;

export const NavBottomCard = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: black;
`;

export const NavTopCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: black;
`;





//homecontainer
export const HomeContainer = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-direction:${props=> props.horizontal ? 'row' : 'column' };
justify-content: center;
align-items: center;
`

export const HomeLeft = styled.div`
flex:${props=>props.horizontal === true ? 1 :1};
height:${props=>props.horizontal ? '100%':'50vh'} ;
width:100%;
overflow: hidden;
display: grid;
grid-template-rows:${props=>props.horizontal ? '.30fr 3fr':'3fr .30fr'} ;
`

export const HomeRight = styled.div`
flex:1;
height:${props=>props.horizontal ? '50vh':'100vh'} ;
width:100%;
height: 100%;
display: grid;
grid-template-rows: 1.5fr 4fr;
grid-template-rows:${props=>props.horizontal ? '4fr 1fr':'2.1fr 4fr'} ;
margin-right:${props=>props.horizontal ? '10px':"0px" };
`


export const ListContainer = styled.div`
max-height: 100%;
overflow: hidden;
margin-right:0px;
margin-left:0px;
background-color: #ffffff;`







export const FilterComponentContainer = styled.div`
width: calc(100% -20px);
height: 56px;
background-color: #000000;
margin-right:10px;
margin-left:10px;

margin-top:${props => props.horizontal ? 'none':"10px"};

  border-radius: 5px;
  /* padding:5px; */
`

export const FilterModal = styled(motion.div)`
width: 1032px;
width:${props => props.horizontal ? '1032px':"1059px"};
height: 486px;
flex-shrink: 0;
 border-radius: 0px 0px 5px 5px;
 position: absolute;
 z-index: 999;
 top:-4px;
 display:flex;

background: rgba(255, 255, 255, 0.96);
box-shadow: 0px 3.9643666744232178px 8.245882987976074px 0px rgba(0, 0, 0, 0.04), 0px 9.544669151306152px 19.852909088134766px 0px rgba(0, 0, 0, 0.05), 0px 31.66034698486328px 65.85352325439453px 0px rgba(0, 0, 0, 0.07);
backdrop-filter: blur(5.540560722351074px);
`;


export const FilterLeft = styled(motion.div)`
 width:100%;
 height:100%;
 flex:1;
display:flex;
justify-content:left;
overflow-y:scroll;
`;

export const FilterRight = styled(motion.div)`
 width:100%;
 height:100%;
 background: rgba(255, 255, 255, 0.32);
box-shadow: 0px 35.37356948852539px 73.5770263671875px 0px rgba(0, 0, 0, 0.05);
backdrop-filter: blur(20.533918380737305px);
 display: flex;
 flex-direction: column;
padding-top:25px;
 align-items: center;
 gap:30px;
 flex:.8;
`

export const FilterButton = styled(motion.div)`
width: 368px;

flex-shrink: 0;
border-radius: 5.002px;
background: #FFF;
box-shadow: 0px 5.0024261474609375px 25.012126922607422px 0px rgba(0, 0, 0, 0.10);
color: #101010;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
display:flex;
justify-content: center;
align-items: center;

height:   ${(props) => (props.horizontal ? '62px' : '74px')};
`;



export const FilterCategoriesContainer = styled(motion.div)`
display: inline-flex;
flex-direction: column;
align-items: flex-start;

/* overflow: scroll; */
width: 100% !important;
height:100%;


`;


export const FilterCatItem = styled(motion.div)`
  display: flex;
  justify-content: left;
  align-items: center;
  /* gap:1rem; */
border-top: 0.428px solid rgba(189, 189, 189, 0.20);
background:   ${(props) => (props.isEven ? '#ECECEC' : '#E8E8E8')};
box-shadow: 0px 14.994817733764648px 31.189224243164062px 0px rgba(0, 0, 0, 0.05);
backdrop-filter: blur(8.704307556152344px);

height:108px;
/* height: 89.151px; */
flex-shrink: 0;

`;










export const FilterCatIcon = styled(motion.div)`
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
color: #F8F8F8;
font-family: Inter;
font-size: 19.669px;
font-style: normal;
font-weight: 400;
line-height: normal;
color:white; 
/* width: 72px;
height: 72px; */
width:   ${(props) => (props.horizontal ? '62px' : '74px')};
height:   ${(props) => (props.horizontal ? '62px' : '74px')};
flex-shrink: 0;

`



export const FilterTextContainer = styled(motion.div)`
display: flex;
flex-direction: column;
justify-content: left;
align-items: left;
text-align: left;
padding-left:2rem;
`;


export const FilterCatTitle = styled(motion.div)`
color: #000;
font-family: Inter;
font-size: 19.669px;
font-style: normal;
font-weight: 400;
line-height: normal;

`;


export const FilterCatSubtitle = styled(motion.div)`
color: #000;
font-family: Inter;
font-size: 13.113px;
font-style: normal;
font-weight: 300;
line-height: normal;
`;

export const Circle = styled(motion.div)`
  border-radius: 100px;
background: #FFF;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);

width: 72px;
height: 72px;

flex-shrink: 0;
`