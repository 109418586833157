import React, { useState, memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "./styles.css";

const App = ({ cara }) => {
  const [swiperIndex, setSwiperIndex] = useState(0);

  return (
    <>
      {/* Preloading images */}
      {cara.map((c, i) => 
        c.fileType === "image" ? <link key={i} rel="preload" as="image" href={c.fileName}/> : null
      )}

      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        autoplay={{
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper"
        onActiveIndexChange={(swiperCore) => {
          setSwiperIndex(swiperCore.activeIndex);
        }}
      >
        {cara.map((c, i) => (
          <SwiperSlide key={c.fileName /* Assuming filenames are unique */} data-swiper-autoplay={Number(c.duration) * 1000}>
            {c.fileType === "image" ? (
              <div>
                <img alt={c.name} src={c.fileName} onError={(e) => { e.target.onerror = null; e.target.src = "/path-to-fallback-image.jpg"; }} />
              </div>
            ) : c.fileType === "video" ? (
              <div>
                <video
                  style={{ height: "100%", width: "100%", objectFit: 'cover' }}
                  autoPlay
                  muted
                  loop
                  onError={(e) => { /* Handle video loading errors, maybe display a fallback */ }}
                >
                  <source src={c.fileName} type="video/mp4" />
                </video>
              </div>
            ) : (
              <div>No content</div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default memo(App);