import styled from 'styled-components'
import {motion} from 'framer-motion'



export const FlipWrapper = styled(motion.div)`
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
`

export const FlipSvg = styled(motion.div)`
 
`

export const FlipText= styled(motion.div)`
    width:25vw;
    text-align:center;
    color: #000;

text-align: center;
font-family: Work Sans;
font-size: 2vw;
font-style: normal;
font-weight: 400;
line-height: normal;
`
