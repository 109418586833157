import styled from 'styled-components'

//homecontainer
export const HomeContainer = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-direction:${props=> props.horizontal ? 'row' : 'column' };
justify-content: center;
align-items: center;
`

export const HomeLeft = styled.div`
flex:${props=>props.horizontal === true ? 1 :1};
height:${props=>props.horizontal ? '100%':'50vh'} ;
width:100%;
overflow: hidden;
display: grid;
grid-template-rows:${props=>props.horizontal ? '.30fr 3fr':'3fr .30fr'} ;
`

export const HomeRight = styled.div`
flex:1;
height:${props=>props.horizontal ? '50vh':'100vh'} ;
width:100%;
height: 100%;
display: grid;
grid-template-rows: 1.5fr 4fr;
grid-template-rows:${props=>props.horizontal ? '4fr 1fr':'1.5fr 4fr'} ;
margin-right:${props=>props.horizontal ? '10px':"0px" };
`


export const ListContainer = styled.div`
max-height: 100%;
overflow: hidden;
margin-right:10px;
margin-left:10px;
background-color: #ffffff;

`




export const FilterComponentContainer = styled.div`
width: calc(100% -20px);
height: 100%;
background-color: #000000;
margin-right:10px;
margin-left:10px;

margin-top:${props => props.horizontal ? 'none':"10px"};

`

