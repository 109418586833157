import styled from "styled-components";
import { motion } from "framer-motion";

export const MenuContainer = styled.div`
  grid-area: menu;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.template === 2 ? "0px" : "10px")};
`;

export const MainAppContainer = styled.div`
  grid-area: main;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: transparent;
`;

export const MenuBottomContainer = styled.div`
  grid-area: botmenu;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.template === 2 ? "0px" : "10px")};
`;

export const NavBottomCard = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: black;
`;

export const NavTopCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: black;
`;

//homecontainer
export const HomeContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? "row" : "column")};
  justify-content: center;
  align-items: center;
`;

export const HomeLeft = styled.div`
  /* flex: 1; */
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: ${(props) =>
    props.deviceType === "Vertical" ? "110px 3fr" : "65px 3fr"};
`;

export const HomeRight = styled.div`
  flex: 1;
  height: ${(props) => (props.horizontal ? "50vh" : "100vh")};
  width: 100%;
  height: 100%;
  display: grid;
  background: white;

  padding: ${(props) => (props.deviceType === "Vertical" ? "16px" : "")};
  /* grid-template-rows: 1.5fr 4fr;
grid-template-rows:${(props) => (props.horizontal ? "4fr 1fr" : "2.1fr 4fr")} ;
margin-right:${(props) => (props.horizontal ? "10px" : "0px")}; */
`;

export const ListContainer = styled.div`
  max-height: 100%;
  overflow: hidden;
  margin-right: 0px;
  margin-left: 0px;
  background-color: transparent;
`;

export const FilterComponentContainer = styled.div`
  width: calc(100% -20px);

  height: ${(props) => (props.deviceType === "Vertical" ? "78px" : "45px")};
  background-color: #000000;
  margin-top: ${(props) => (props.horizontal ? "none" : "10px")};
  padding: 0px 14px;
  border-radius: 5px;
  /* padding:5px; */
`;

export const FilterModal = styled(motion.div)`
  height: ${(props) =>
    props.deviceType === "Mobile"
      ? "100%"
      : props.deviceType === "Vertical" && !props.searchOpen
      ? "100%"
      : "390px"};
  width: 100%;
  flex-shrink: 0;
  border-radius: 0px 0px 5px 5px;
  position: absolute;
  z-index: 999;

  margin-top:0px;
  display: flex;
  flex-direction: ${(props) =>
    props.deviceType === "Mobile" ? "column-reverse" : "row"};
  background: rgba(255, 255, 255, 0.96);
  box-shadow: 0px 3.9643666744232178px 8.245882987976074px 0px
      rgba(0, 0, 0, 0.04),
    0px 9.544669151306152px 19.852909088134766px 0px rgba(0, 0, 0, 0.05),
    0px 31.66034698486328px 65.85352325439453px 0px rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(5.540560722351074px);
  padding-bottom:${(props) =>
    props.filterOpen
      ? "16px"
      : '0px'};
  overflow-x: hidden;
`;

export const FilterModalMobile = styled(motion.div)`
  width: 100%;
  position: absolute;
  top: 1.2rem;
  height: 486px;
  flex-shrink: 0;
  border-radius: 0px 0px 5px 5px;
  z-index: 999;
  display: flex;
  background: rgba(255, 255, 255, 0.96);
  box-shadow: 0px 3.9643666744232178px 8.245882987976074px 0px
      rgba(0, 0, 0, 0.04),
    0px 9.544669151306152px 19.852909088134766px 0px rgba(0, 0, 0, 0.05),
    0px 31.66034698486328px 65.85352325439453px 0px rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(5.540560722351074px);
`;

export const FilterLeft = styled(motion.div)`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: left;
  overflow-y: scroll;
`;

export const FilterRight = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.32);
  box-shadow: 0px 35.37356948852539px 73.5770263671875px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(20.533918380737305px);
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => (props.deviceType === "Mobile" ? "0px" : "25px")};
  align-items: center;
  gap: 30px;
  gap: ${(props) => (props.deviceType === "Mobile" ? "0rem" : "30pxpx")};
  flex: ${(props) => (props.deviceType === "Mobile" ? "0" : ".8")};
`;

export const FilterButton = styled(motion.div)`
  width: 90%;
  flex-shrink: 0;
  border-radius: 5px 5px 0px 0px;
  background: #fff;
  box-shadow: 0px 5.0024261474609375px 25.012126922607422px 0px
    rgba(0, 0, 0, 0.1);
  color: #101010;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.horizontal ? "62px" : "74px")};
`;

export const FilterCategoriesContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: scroll;
  max-height: 62vh;
  flex-wrap: wrap;
  padding: ${(props) => (props.deviceType === "Vertical" ? "2rem" : ".33rem")};

  height: auto;
  gap: ${(props) => (props.deviceType === "Vertical" ? "2.7rem" : ".5rem")};

  justify-content: center;
  align-items: center;
`;

export const FilterCatIcon = styled(motion.div)`
  border-radius: 50%;
  border-radius: ${(props) => (props.longIcon ? "50px" : "50%")};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f8f8f8;
  font-family: 'Raleway';
  font-size: 19.669px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: white;
  height: 65px;
  transform: ${(props) =>
    props.deviceType !== "Vertical" 
      ? "scale(1)"
      : "scale(1.2)"};
  margin-left: ${(props) => (props.longIcon ? "0px" : "0px")};
  background:${(props) => (props.longIcon ? "#3A3C6B" : "#2A2B3C")};
  width: ${(props) => (props.longIcon ? "140px" : "65px")};
  cursor: pointer;
`;

export const FilterCategoriesContainerInside = styled(motion.div)`
  display: inline-flex;
  flex-direction: ${(props) =>
    props.deviceType === "Vertical" ? "row" : "column"};
  align-items: center;
  /* overflow: scroll; */
  flex-wrap: ${(props) => (props.deviceType === "Vertical" ? "wrap" : "none")};
  height: 100%;
  gap: ${(props) => (props.deviceType === "Vertical" ? "1rem" : "none")};
  height: 100%;
  justify-content: left;
  align-items: center;
  flex-shrink: 0;
`;

export const FilterCatItem = styled(motion.div)`
  display: flex;
  justify-content: left;
  align-items: center;
  /* gap:1rem; */
  border-top: 0.428px solid rgba(189, 189, 189, 0.2);
  background: ${(props) => (props.isEven ? "#ECECEC" : "#E6E6E6")};
  box-shadow: 0px 14.994817733764648px 31.189224243164062px 0px
    rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(8.704307556152344px);

  height: 108px;
  height: ${(props) => (props.deviceType === "Mobile" ? "5rem" : "108px")};
  /* height: 89.151px; */
  flex-shrink: 0;
  cursor: pointer;
`;

export const FilterTextContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  text-align: left;
  padding-left: 2rem;
`;

export const FilterCatTitle = styled(motion.div)`
  color: #000;
  font-family: Inter;
  font-size: 19.669px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const FilterCatSubtitle = styled(motion.div)`
  color: #000;
  font-family: Inter;
  font-size: 13.113px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export const Circle = styled(motion.div)`
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  width: 72px;
  height: 72px;
  flex-shrink: 0;
`;

export const MediaSwitch = styled(motion.div)`
  position: absolute;
  top: 22px;
  right: -27px;
  width: 97px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 30px;
  display: flex;
  justify-content: flex-start;
  padding-left: 1.5rem;
  align-items: center;
  z-index: 999;
  background: #fff;
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1),
    0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 49px 29px 0px rgba(0, 0, 0, 0.05),
    0px 87px 35px 0px rgba(0, 0, 0, 0.01), 0px 136px 38px 0px rgba(0, 0, 0, 0);
  border-radius: 30px;
  background: #fff;
  cursor: pointer;
  box-shadow: 0px 0px 3px 0px rgba(102, 102, 102, 0.1),
    0px 0px 6px 0px rgba(102, 102, 102, 0.09),
    0px 0px 8px 0px rgba(102, 102, 102, 0.05),
    0px 0px 9px 0px rgba(102, 102, 102, 0.01), 0px 0px 10px 0px rgba(0, 0, 0, 0);
`;

export const TableContainerTop = styled(motion.div)`
  background: #545454;
  height: 40px;
  display: flex;
  width: 100%;
  padding: 16px 0px 13px 0px;
  justify-content: center;
  align-items: center;
  color: white;
`;
