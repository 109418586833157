import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "react-zoom-pan-pinch";
import {
  FloorsContainer,
  FloorsControlMain,
  FloorsControlBottomContainer,
  FloorsControlTopContainer,
  FloorsControlButton,
  FloorsControlButtonReset,
  PlusMinusButtonContainer,
} from "../Floors/Floors.styled";
import FloorOne from "../../Components/Floors/FloorOne";
import LowerLevel from "../../Components/Floors/LowerLevel";
import FloorTwo from "../../Components/Floors/FloorTwo";
import resetButton from '../../Assets/resetButton.svg';
import { AspectRatio } from "@mui/icons-material";

const Floors = ({ horizontal, vert, setFloorFunc, setZoomResetVal }) => {
  const [active, setActive] = useState();
  const floor = useSelector((state) => state.floor);
  const { floorNum, number } = floor;
  const dispatch = useDispatch();
  const [matchUnit,setMatchedUnit] = useState('')

  const screenReducer = useSelector((state) => state.screenReducer);
  const { access, projects, units } = screenReducer;
  const [currentFloorNumber, setCurrentFloorNumber] = useState();
  const transformComponentRef = useRef(null);

 //Svg selector 
 const [previousPath, setPreviousPath] = useState(null);
    const [previousFillColor, setPreviousFillColor] = useState('white');
  const themeReducer = useSelector((state)=>state.themeReducer)
  const {svgChoice:{unit}} = themeReducer
  const [forceUpdate, setForceUpdate] = useState(0);

  useEffect(() => {
    if (svgPathsRef.current && units) {
        const pathsInsideG = Array.from(svgPathsRef.current.querySelectorAll("g[id^='g'] path"));
        const pathToCompanyName = units.reduce((acc, unit) => {
            acc[unit.path] = unit.companyName;
            return acc;
        }, {});

        pathsInsideG.forEach((path) => {
            const parentG = path.closest('g');
            const gID = parentG?.id;

            if (pathToCompanyName[gID] && !parentG.querySelector('text')) {
                const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
                const bbox = path.getBBox();
                textElement.setAttribute("x", bbox.x + bbox.width / 2);
                textElement.setAttribute("y", bbox.y + bbox.height / 2);
                textElement.setAttribute("text-anchor", "middle");
                textElement.setAttribute("dominant-baseline", "central");
                textElement.setAttribute("transform", `scale(1,-1) translate(0,-${2*(bbox.y + bbox.height / 2)})`);
                textElement.textContent = pathToCompanyName[gID];
                parentG.appendChild(textElement);
            }

            const matchingUnit = units.find(u => u.companyName === unit);
            if (matchingUnit && matchingUnit.path === gID) {
                if (previousPath) {
                    previousPath.style.fill = previousFillColor;
                }
                setPreviousFillColor(path.style.fill);
                path.style.fill = 'lightblue';
                setPreviousPath(path);
            }
        });

        if (!previousPath && forceUpdate === 0) {
            setForceUpdate(1);
        }
    }
}, [units, unit, previousFillColor, previousPath, forceUpdate]);




  useEffect(() => {
    if (transformComponentRef.current && setZoomResetVal) {
      setZoomResetVal(transformComponentRef.current);
      setCurrentFloorNumber(access?.[0]?.kioskId);
    }
}, []);

  useEffect(() => {
    if (floorNum) {
      setActive(floorNum);
    }
  }, [floorNum]);

  const resetMainZoom = () => {
    if (transformComponentRef.current) {
      const { resetTransform } = transformComponentRef.current;
      resetTransform();
    }
  };

  const zoomIn = () => {
    if (transformComponentRef.current) {
      const { zoomIn } = transformComponentRef.current;
      zoomIn();
    }
  };

  const zoomOut = () => {
    if (transformComponentRef.current) {
      const { zoomOut } = transformComponentRef.current;
      zoomOut();
    }
  };

  useEffect(() => {
    if (floorNum !== currentFloorNumber) {
      resetMainZoom();
      setCurrentFloorNumber(floorNum);
    }
  }, [floorNum, currentFloorNumber]);

  // Determine the component to be rendered based on the screenId and floorNum
  let ComponentToRender;


    // If neither kioskId nor floorNum matches, render based on floorNum
    if (floorNum === '0') {
      ComponentToRender = LowerLevel;
    } else if (floorNum === '1') {
      ComponentToRender = FloorOne;
    } else if (floorNum === '2') {
      ComponentToRender = FloorTwo;
    } else{
      ComponentToRender = access[0].kioskId === 's-2' ||access[0].kioskId === 's-3' ? LowerLevel:FloorOne;
    }
  
    const svgPathsRef = useRef([]); // Ref to store the SVG paths during initial render
    const svgUrl = projects?.[0]?.svgMaps[0]?.map ?? '';
    const [svgContent, setSvgContent] = useState(''); // Assuming you have this state setter
    
    useEffect(() => {
        const rawData = svgUrl.split(',')[1] || svgUrl;
        try {
            let decodedSvg = atob(rawData);
            decodedSvg = decodedSvg.replace('<svg', '<svg width="95vw" height="auto"');
            setSvgContent(decodedSvg);
        } catch (error) {
            console.error('Error decoding the base64 SVG data:', error);
        }
    }, [svgUrl]);




  return (
    <TransformWrapper
      initialScale={1}
      initialPositionX={0}
      initialPositionY={0}
      ref={transformComponentRef}
    >
      {({ resetTransform, ...rest }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            position: "relative",
          }}
        >
          <FloorsControlMain style={{ position: "absolute", zIndex: 799 }}>
            <FloorsControlTopContainer>
              {/* <FloorsControlButton
                color={
                  themeData && themeData.cardBgColor && themeData.cardBgColor
                }
                onClick={() => setFloorFunc("0")}
                selected={floorNum.toString() === "0"}
              >
                L
              </FloorsControlButton>
              <FloorsControlButton
                color={
                  themeData && themeData.cardBgColor && themeData.cardBgColor
                }
                onClick={() => setFloorFunc("1")}
                selected={
                  floorNum.toString() !== "0" && floorNum.toString() !== "2"
                }
              >
                1
              </FloorsControlButton>
              <FloorsControlButton
                color={
                  themeData && themeData.cardBgColor && themeData.cardBgColor
                }
                onClick={() => setFloorFunc("2")}
                selected={floorNum.toString() === "2"}
              >
                2
              </FloorsControlButton> */}
            </FloorsControlTopContainer>
            <FloorsControlBottomContainer>
              <FloorsControlButtonReset onClick={() => resetTransform()}>
               <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
  <g filter="url(#filter0_dddd_653_225)">
    <rect x="9" y="9" width="47" height="47" rx="5" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.0895 27.4132C26.6725 24.6889 29.6225 22.8572 33.0003 22.8572C38.0497 22.8572 42.1431 26.9506 42.1431 32C42.1431 37.0495 38.0497 41.1429 33.0003 41.1429C27.9508 41.1429 23.8574 37.0495 23.8574 32" fill="white"/>
    <path d="M25.0895 27.4132C26.6725 24.6889 29.6225 22.8572 33.0003 22.8572C38.0497 22.8572 42.1431 26.9506 42.1431 32C42.1431 37.0495 38.0497 41.1429 33.0003 41.1429C27.9508 41.1429 23.8574 37.0495 23.8574 32" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M35.2853 32.0001C35.2853 33.2624 34.2619 34.2858 32.9996 34.2858C31.7372 34.2858 30.7139 33.2624 30.7139 32.0001C30.7139 30.7377 31.7372 29.7144 32.9996 29.7144C34.2619 29.7144 35.2853 30.7377 35.2853 32.0001Z" fill="white" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.5713 27.4286H24.9999V22.8572" fill="white"/>
    <path d="M29.5713 27.4286H24.9999V22.8572" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <filter id="filter0_dddd_653_225" x="0" y="0" width="65" height="65" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="1.5"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.4 0 0 0 0 0.4 0 0 0 0 0.4 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_653_225"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="3"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.4 0 0 0 0 0.4 0 0 0 0 0.4 0 0 0 0.09 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow_653_225" result="effect2_dropShadow_653_225"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="4"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.4 0 0 0 0 0.4 0 0 0 0 0.4 0 0 0 0.05 0"/>
      <feBlend mode="normal" in2="effect2_dropShadow_653_225" result="effect3_dropShadow_653_225"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="4.5"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.4 0 0 0 0 0.4 0 0 0 0 0.4 0 0 0 0.01 0"/>
      <feBlend mode="normal" in2="effect3_dropShadow_653_225" result="effect4_dropShadow_653_225"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_653_225" result="shape"/>
    </filter>
  </defs>
</svg>
              </FloorsControlButtonReset>
              <PlusMinusButtonContainer>
              <FloorsControlButton style={{fontSize: '35px'}} onClick={() => zoomIn()}>
                +
              </FloorsControlButton>
              <hr style={{  border: 'none',         // Remove default border
    borderTop: '1px solid red', // Add top border with light gray color
    margin: '0',            }}/>
              <FloorsControlButton style={{fontSize: '35px'}} onClick={() => zoomOut()}>
                -
              </FloorsControlButton>
              </PlusMinusButtonContainer>
              
            </FloorsControlBottomContainer>
          </FloorsControlMain>
          <TransformComponent>
            <FloorsContainer
              style={{
                width: vert ? "100vw" : "50vw",
                background: "transparent",
              }}
              horizontal={horizontal}
            >
              {/* <ComponentToRender
                screenId={access?.[0]?.kioskId}
                One={number.toString()}
                color={themeData?.cardBgColor}
                style={{ aspectRatio: "16 / 9" }}
              /> */}
                  <div       ref={svgPathsRef}>
       <div 


      // Using dangerouslySetInnerHTML to insert decoded SVG content
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
    </div>
            </FloorsContainer>
          </TransformComponent>
        </div>
      )}
    </TransformWrapper>
  );
};

export default Floors;