import styled from "styled-components";

// export const MainAppWrapper = styled.div`
//   background-color: ${({ themeData }) =>
//     themeData ? themeData.bodyBgColor : "white"};
//   /* height:100vh; */
//   width: 100v;
//   height: calc(100 * var(--vh));

//   @media (min-width: 480px) {
//     padding: ${({ themeData }) =>
//       themeData ? themeData.mobileLandPadding : "0px"};
//     height: calc(
//       100 * var(--vh)
//     ); // Use the dynamic viewport height only for screens <= 768px
//   }
//   @media (min-width: 768px) {
//     padding: ${({ themeData }) =>
//       themeData ? themeData.tabletPadding : "0px"};
//     height: calc(100 * var(--vh));
//   }
//   @media (min-width: 992px) {
//     padding: ${({ themeData }) =>
//       themeData ? themeData.baseDeskPadding : "0px"};
//   }
//   @media screen and (device-width: 1080px) and (device-height: 1920px) {
//     padding: ${({ themeData }) =>
//       themeData ? themeData.kioskVertPadding : "0px"};
//   }
//   @media screen and (device-width: 1920px) and (device-height: 1080px) {
//     padding: ${({ themeData }) =>
//       themeData ? themeData.kioskHorPadding : "0px"};
//   }
// `;
export const MainAppWrapper = styled.div`
  background-color: ${({ themeData }) =>
    themeData ? themeData.bodyBgColor : "white"};
  width: 100vw;
  height: calc(100 * var(--vh));
  
  padding: ${({ deviceType, themeData }) => {
    switch (deviceType) {
      case "Mobile Landscape":
        return themeData ? themeData.mobileLandPadding : "0px";
      case "Tablet":
        return themeData ? themeData.tabletPadding : "0px";
      case "Tablet Landscape":
        return themeData ? themeData.baseDeskPadding : "0px";
      case "Vertical":
        return themeData ? themeData.kioskVertPadding : "0px";
      case "Horizontal":
        return themeData ? themeData.kioskHorPadding : "0px";
      default:
        return themeData ? themeData.mobilePadding : "0px";
    }
  }};


`;

export const EditorHeaderView = styled.div`
  /* position: relative;
    width: 100%; 
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 259 / 47;
    margin:0rem ;
    height: 100%; */
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 50px;
  overflow: hidden;
  aspect-ratio: 259 / 47 !important;
`;

// export const AppContainer = styled.div`
//   background-color: ${({ themeData }) =>
//     themeData ? themeData.bodyBgColor : "white"};
//   height: 100%;
//   max-width: 100%;
//   text-align: center;
//   overflow: hidden;
//   display: grid;
//   padding:0px;
//   gap:0px;
//   margin: 0px;

//   grid-template-areas:
//     "menu"
//     "main"
//     "botmenu";
//   grid-template-rows: ${({ themeData }) =>
//     themeData
//       ? `${themeData.mobileHeaderHeight} ${themeData.mobileBodyHeight} ${themeData.mobileFooterHeight}`
//       : "10% 80% 10%"};

// @media (min-width: 320px) {
//   padding: ${({ themeData }) => (themeData ? themeData.mobilePadding : "0px")};
//     gap: ${({ themeData }) => (themeData ? themeData.mobileGap : "0px")};
//     margin:'0px'
//     grid-template-rows: ${(props) => {
//       const { themeData } = props;

//       return `${themeData.mobileHeaderHeight} ${themeData.mobileBodyHeight} ${themeData.mobileFooterHeight}`;
//     }}
// }

// //tablet

//   @media (min-width: 768px) { 
//     padding: ${({ themeData }) => (themeData ? themeData.tabletPadding : "0")};
//     gap: ${({ themeData }) => (themeData ? themeData.tabletGap : "0")};
    
//     grid-template-rows: ${(props) => {
//       const { themeData } = props;

//       return `${themeData.tabletHeaderHeight} ${themeData.tabletBodyHeight} ${themeData.tabletFooterHeight}`;
//     }}
//   }

//  /* Base Desktop Styles */
// @media (min-width: 992px) {
//   padding: ${({ themeData }) => (themeData ? themeData.baseDeskPadding : "0")};
//     gap: ${({ themeData }) => (themeData ? themeData.baseDeskGap : "0")};
    
//     grid-template-rows: ${(props) => {
//       const { themeData } = props;

//       return `${themeData.baseDeskHeaderHeight} ${themeData.baseDeskBodyHeight} ${themeData.baseDeskFooterHeight}`;
//     }}
// }



// /* Vertical Styles */
// @media screen and (device-width: 1080px) and (device-height: 1920px) {
//   padding: ${({ themeData }) => (themeData ? themeData.kioskVertPadding : "0")};
//     gap: ${({ themeData }) => (themeData ? themeData.kioskVertGap : "0")};
    
//     grid-template-rows: ${(props) => {
//       const { themeData } = props;

//       return `${themeData.kioskVertHeaderHeight} ${themeData.kioskVertBodyHeight} ${themeData.kioskVertFooterHeight}`;
//     }}
// }

// /* Horizontal Styles */
// @media screen and (device-width: 1920px) and (device-height: 1080px) {
//   padding: ${({ themeData }) => (themeData ? themeData.kioskHorPadding : "0")};
//     gap: ${({ themeData }) => (themeData ? themeData.kioskHorGap : "0")};
    
//     grid-template-rows: ${(props) => {
//       const { themeData } = props;

//       return `${themeData.kioskHorHeaderHeight} ${themeData.kioskHorBodyHeight} ${themeData.kioskHorFooterHeight}`;
//     }}

// }

// `;

export const AppContainer = styled.div`
  background-color: ${({ themeData }) =>
    themeData ? themeData.bodyBgColor : "white"};
  height: 100%;
  max-width: 100%;
  text-align: center;
  overflow: hidden;
  display: grid;
  padding: 0px;
  gap: 0px;
  margin: 0px;

  grid-template-areas:
    "menu"
    "main"
    "botmenu";
  
    grid-template-rows: ${({ deviceType, themeData }) => {
  if (!themeData) {
    return "10% 80% 10%"; // Default row heights if themeData is not provided
  }

  switch (deviceType) {
    case "Mobile":
      return `${themeData.mobileHeaderHeight || 'auto'} ${themeData.mobileBodyHeight || 'auto'} ${themeData.mobileFooterHeight || 'auto'}`;
    case "Mobile Landscape":
      return `${themeData.mobileLandHeaderHeight || 'auto'} ${themeData.mobileLandBodyHeight || 'auto'} ${themeData.mobileLandFooterHeight || 'auto'}`;
    case "Tablet":
      return `${themeData.tabletHeaderHeight || 'auto'} ${themeData.tabletBodyHeight || 'auto'} ${themeData.tabletFooterHeight || 'auto'}`;
    case "Tablet Landscape":
      return `${themeData.baseDeskHeaderHeight || 'auto'} ${themeData.baseDeskBodyHeight || 'auto'} ${themeData.baseDeskFooterHeight || 'auto'}`;
    case "Vertical":
      return `${themeData.kioskVertHeaderHeight || 'auto'} ${themeData.kioskVertBodyHeight || 'auto'} ${themeData.kioskVertFooterHeight || 'auto'}`;
    case "Horizontal":
      return `${themeData.kioskHorHeaderHeight || 'auto'} ${themeData.kioskHorBodyHeight || 'auto'} ${themeData.kioskHorFooterHeight || 'auto'}`;
    default:
      return "10% 80% 10%"; // Default row heights
  }
}};

  
  padding: ${({ deviceType, themeData }) => {
    switch (deviceType) {
      case "Mobile":
        return themeData ? themeData.mobilePadding : "0px";
      case "Mobile Landscape":
        return themeData ? themeData.mobileLandPadding : "0px";
      case "Tablet":
        return themeData ? themeData.tabletPadding : "0px";
      case "Tablet Landscape":
        return themeData ? themeData.baseDeskPadding : "0px";
      case "Vertical":
        return themeData ? themeData.kioskVertPadding : "0px";
      case "Horizontal":
        return themeData ? themeData.kioskHorPadding : "0px";
      default:
        return "0px"; // Default padding
    }
  }};
  
  gap: ${({ deviceType, themeData }) => {
    switch (deviceType) {
      case "Mobile":
        return themeData ? themeData.mobileGap : "0px";
      case "Mobile Landscape":
        return themeData ? themeData.mobileLandGap : "0px";
      case "Tablet":
        return themeData ? themeData.tabletGap : "0px";
      case "Tablet Landscape":
        return themeData ? themeData.baseDeskGap : "0px";
      case "Vertical":
        return themeData ? themeData.kioskVertGap : "0px";
      case "Horizontal":
        return themeData ? themeData.kioskHorGap : "0px";
      default:
        return "0px"; // Default gap
    }
  }};
`;
export const AppContainer2 = styled.div``;

export const MenuContainer = styled.div`
  grid-area: menu;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const MainAppContainer = styled.div`
  grid-area: main;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: transparent;
`;

export const MenuBottomContainer = styled.div`
  grid-area: botmenu;
  color: white;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ themeData }) =>
    themeData ? themeData.footerBgColor : "white"};
  padding:0px;
  margin: 0px;

`;

export const NavBottomCard = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: black;
`;

export const NavTopCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
`;

export const EditorCardView = styled.div`
  position: relative;
  max-width: 30vw;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  border: solid 1px red;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 166 / 63;
  margin: 0rem 2rem;
  display: flex;
`;

///MEDIA QUERIES

//HEADER TITLE
export const HeaderTitle = styled.div`
  /* Default Mobile Styles */
  color: ${(props) => props.themeData?.mobileTitleStyle?.color || "inherit"};
  font-family: ${(props) =>
    props.themeData?.mobileTitleStyle?.fontFamily || "inherit"};
  font-size: ${(props) =>
    props.themeData?.mobileTitleStyle?.fontSize || "inherit"};
  font-style: ${(props) =>
    props.themeData?.mobileTitleStyle?.fontStyle || "normal"};
  font-weight: ${(props) =>
    props.themeData?.mobileTitleStyle?.fontWeight || "normal"};
  line-height: ${(props) =>
    props.themeData?.mobileTitleStyle?.lineHeight || "normal"};
  text-align: ${(props) =>
    props.themeData?.mobileTitleStyle?.textAlign || "center"};
  text-decoration: ${(props) =>
    props.themeData?.mobileTitleStyle?.textDecoration || "none"};

  /* Other styles... */

  /* Mobile Landscape Styles */
  @media (min-width: 480px) {
    color: ${(props) =>
      props.themeData?.mobileLandTitleStyle?.color || "inherit"};
    font-family: ${(props) =>
      props.themeData?.mobileLandTitleStyle?.fontFamily || "inherit"};
    font-size: ${(props) =>
      props.themeData?.mobileLandTitleStyle?.fontSize || "inherit"};
    font-style: ${(props) =>
      props.themeData?.mobileLandTitleStyle?.fontStyle || "normal"};
    font-weight: ${(props) =>
      props.themeData?.mobileLandTitleStyle?.fontWeight || "normal"};
    line-height: ${(props) =>
      props.themeData?.mobileLandTitleStyle?.lineHeight || "normal"};
    text-align: ${(props) =>
      props.themeData?.mobileLandTitleStyle?.textAlign || "center"};
    text-decoration: ${(props) =>
      props.themeData?.mobileLandTitleStyle?.textDecoration || "none"};
    /* Other styles... */
  }

  /* Tablet Styles */
  @media (min-width: 768px) {
    color: ${(props) => props.themeData?.tabletTitleStyle?.color || "inherit"};
    font-family: ${(props) =>
      props.themeData?.tabletTitleStyle?.fontFamily || "inherit"};
    font-size: ${(props) =>
      props.themeData?.tabletTitleStyle?.fontSize || "inherit"};
    font-style: ${(props) =>
      props.themeData?.tabletTitleStyle?.fontStyle || "normal"};
    font-weight: ${(props) =>
      props.themeData?.tabletTitleStyle?.fontWeight || "normal"};
    line-height: ${(props) =>
      props.themeData?.tabletTitleStyle?.lineHeight || "normal"};
    text-align: ${(props) =>
      props.themeData?.tabletTitleStyle?.textAlign || "center"};
    text-decoration: ${(props) =>
      props.themeData?.tabletTitleStyle?.textDecoration || "none"};
    /* Other styles... */
  }

  /* Base Desktop Styles */
  @media (min-width: 992px) {
    color: ${(props) =>
      props.themeData?.baseDeskTitleStyle?.color || "inherit"};
    font-family: ${(props) =>
      props.themeData?.baseDeskTitleStyle?.fontFamily || "inherit"};
    font-size: ${(props) =>
      props.themeData?.baseDeskTitleStyle?.fontSize || "inherit"};
    font-style: ${(props) =>
      props.themeData?.baseDeskTitleStyle?.fontStyle || "normal"};
    font-weight: ${(props) =>
      props.themeData?.baseDeskTitleStyle?.fontWeight || "normal"};
    line-height: ${(props) =>
      props.themeData?.baseDeskTitleStyle?.lineHeight || "normal"};
    text-align: ${(props) =>
      props.themeData?.baseDeskTitleStyle?.textAlign || "center"};
    text-decoration: ${(props) =>
      props.themeData?.baseDeskTitleStyle?.textDecoration || "none"};
    /* Other styles... */
  }

  /* Horizontal Styles */
  @media (min-width: 1920px) {
    color: ${(props) =>
      props.themeData?.kioskHorTitleStyle?.color || "inherit"};
    font-family: ${(props) =>
      props.themeData?.kioskHorTitleStyle?.fontFamily || "inherit"};
    font-size: ${(props) =>
      props.themeData?.kioskHorTitleStyle?.fontSize || "inherit"};
    font-style: ${(props) =>
      props.themeData?.kioskHorTitleStyle?.fontStyle || "normal"};
    font-weight: ${(props) =>
      props.themeData?.kioskHorTitleStyle?.fontWeight || "normal"};
    line-height: ${(props) =>
      props.themeData?.kioskHorTitleStyle?.lineHeight || "normal"};
    text-align: ${(props) =>
      props.themeData?.kioskHorTitleStyle?.textAlign || "center"};
    text-decoration: ${(props) =>
      props.themeData?.kioskHorTitleStyle?.textDecoration || "none"};
    /* Other styles... */
  }

  /* Vertical Styles */
  @media (min-width: 1080px) and (orientation: portrait) {
    color: ${(props) =>
      props.themeData?.kioskVertTitleStyle?.color || "inherit"};
    font-family: ${(props) =>
      props.themeData?.kioskVertTitleStyle?.fontFamily || "inherit"};
    font-size: ${(props) =>
      props.themeData?.kioskVertTitleStyle?.fontSize || "inherit"};
    font-style: ${(props) =>
      props.themeData?.kioskVertTitleStyle?.fontStyle || "normal"};
    font-weight: ${(props) =>
      props.themeData?.kioskVertTitleStyle?.fontWeight || "normal"};
    line-height: ${(props) =>
      props.themeData?.kioskVertTitleStyle?.lineHeight || "normal"};
    text-align: ${(props) =>
      props.themeData?.kioskVertTitleStyle?.textAlign || "center"};
    text-decoration: ${(props) =>
      props.themeData?.kioskVertTitleStyle?.textDecoration || "none"};
    /* Other styles... */
  }
`;

//HEADER SUBTITLE (DATE)

export const HeaderSubTitle = styled.div`
  /* Default Mobile Styles */
  color: ${(props) => props.themeData?.mobileSubTitleStyle?.color || "inherit"};
  font-family: ${(props) =>
    props.themeData?.mobileSubTitleStyle?.fontFamily || "inherit"};
  font-size: ${(props) =>
    props.themeData?.mobileSubTitleStyle?.fontSize || "inherit"};
  font-style: ${(props) =>
    props.themeData?.mobileSubTitleStyle?.fontStyle || "normal"};
  font-weight: ${(props) =>
    props.themeData?.mobileSubTitleStyle?.fontWeight || "normal"};
  line-height: ${(props) =>
    props.themeData?.mobileSubTitleStyle?.lineHeight || "normal"};
  text-align: ${(props) =>
    props.themeData?.mobileSubTitleStyle?.textAlign || "center"};
  text-decoration: ${(props) =>
    props.themeData?.mobileSubTitleStyle?.textDecoration || "none"};

  /* Other styles... */

  /* Mobile Landscape Styles */
  @media (min-width: 480px) {
    color: ${(props) =>
      props.themeData?.mobileLandSubTitleStyle?.color || "inherit"};
    font-family: ${(props) =>
      props.themeData?.mobileLandSubTitleStyle?.fontFamily || "inherit"};
    font-size: ${(props) =>
      props.themeData?.mobileLandSubTitleStyle?.fontSize || "inherit"};
    font-style: ${(props) =>
      props.themeData?.mobileLandSubTitleStyle?.fontStyle || "normal"};
    font-weight: ${(props) =>
      props.themeData?.mobileLandSubTitleStyle?.fontWeight || "normal"};
    line-height: ${(props) =>
      props.themeData?.mobileLandSubTitleStyle?.lineHeight || "normal"};
    text-align: ${(props) =>
      props.themeData?.mobileLandSubTitleStyle?.textAlign || "center"};
    text-decoration: ${(props) =>
      props.themeData?.mobileLandSubTitleStyle?.textDecoration || "none"};
    /* Other styles... */
  }

  /* Tablet Styles */
  @media (min-width: 768px) {
    color: ${(props) =>
      props.themeData?.tabletSubTitleStyle?.color || "inherit"};
    font-family: ${(props) =>
      props.themeData?.tabletSubTitleStyle?.fontFamily || "inherit"};
    font-size: ${(props) =>
      props.themeData?.tabletSubTitleStyle?.fontSize || "inherit"};
    font-style: ${(props) =>
      props.themeData?.tabletSubTitleStyle?.fontStyle || "normal"};
    font-weight: ${(props) =>
      props.themeData?.tabletSubTitleStyle?.fontWeight || "normal"};
    line-height: ${(props) =>
      props.themeData?.tabletSubTitleStyle?.lineHeight || "normal"};
    text-align: ${(props) =>
      props.themeData?.tabletSubTitleStyle?.textAlign || "center"};
    text-decoration: ${(props) =>
      props.themeData?.tabletSubTitleStyle?.textDecoration || "none"};
    /* Other styles... */
  }

  /* Base Desktop Styles */
  @media (min-width: 992px) {
    color: ${(props) =>
      props.themeData?.baseDeskSubTitleStyle?.color || "inherit"};
    font-family: ${(props) =>
      props.themeData?.baseDeskSubTitleStyle?.fontFamily || "inherit"};
    font-size: ${(props) =>
      props.themeData?.baseDeskSubTitleStyle?.fontSize || "inherit"};
    font-style: ${(props) =>
      props.themeData?.baseDeskSubTitleStyle?.fontStyle || "normal"};
    font-weight: ${(props) =>
      props.themeData?.baseDeskSubTitleStyle?.fontWeight || "normal"};
    line-height: ${(props) =>
      props.themeData?.baseDeskSubTitleStyle?.lineHeight || "normal"};
    text-align: ${(props) =>
      props.themeData?.baseDeskSubTitleStyle?.textAlign || "center"};
    text-decoration: ${(props) =>
      props.themeData?.baseDeskSubTitleStyle?.textDecoration || "none"};
    /* Other styles... */
  }

  /* Horizontal Styles */
  @media (min-width: 1920px) {
    color: ${(props) =>
      props.themeData?.kioskHorSubTitleStyle?.color || "inherit"};
    font-family: ${(props) =>
      props.themeData?.kioskHorSubTitleStyle?.fontFamily || "inherit"};
    font-size: ${(props) =>
      props.themeData?.kioskHorSubTitleStyle?.fontSize || "inherit"};
    font-style: ${(props) =>
      props.themeData?.kioskHorSubTitleStyle?.fontStyle || "normal"};
    font-weight: ${(props) =>
      props.themeData?.kioskHorSubTitleStyle?.fontWeight || "normal"};
    line-height: ${(props) =>
      props.themeData?.kioskHorSubTitleStyle?.lineHeight || "normal"};
    text-align: ${(props) =>
      props.themeData?.kioskHorSubTitleStyle?.textAlign || "center"};
    text-decoration: ${(props) =>
      props.themeData?.kioskHorSubTitleStyle?.textDecoration || "none"};
    /* Other styles... */
  }

  /* Vertical Styles */
  @media (min-width: 1080px) and (orientation: portrait) {
    color: ${(props) =>
      props.themeData?.kioskVertSubTitleStyle?.color || "inherit"};
    font-family: ${(props) =>
      props.themeData?.kioskVertSubTitleStyle?.fontFamily || "inherit"};
    font-size: ${(props) =>
      props.themeData?.kioskVertSubTitleStyle?.fontSize || "inherit"};
    font-style: ${(props) =>
      props.themeData?.kioskVertSubTitleStyle?.fontStyle || "normal"};
    font-weight: ${(props) =>
      props.themeData?.kioskVertSubTitleStyle?.fontWeight || "normal"};
    line-height: ${(props) =>
      props.themeData?.kioskVertSubTitleStyle?.lineHeight || "normal"};
    text-align: ${(props) =>
      props.themeData?.kioskVertSubTitleStyle?.textAlign || "center"};
    text-decoration: ${(props) =>
      props.themeData?.kioskVertSubTitleStyle?.textDecoration || "none"};
    /* Other styles... */
  }
`;
